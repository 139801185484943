<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
// import pagination from 'laravel-vue-pagination';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';
export default {
    name: "companies-table",
    data() {
        return {
            catalogue: new Catalogue(),
            bills: {},
            search: '',
            idConvert: '',
            idUser: '',
        }
    },
    mounted() {
        this.list();
        this.setHeaderTitle('Facturas');
        if (this.authUser.organization_charts_id == 2) {
            this.idUser = this.authUser.id;
            this.catalogue.get_companies_users(this.idUser);
        }
    },
    created() {
        Event.$on("event-load-bills", () => {
            this.list();
        });
    },
    methods: {
        showCheck(edit = {}) {
            Event.$emit('event-show-addChecks', edit);
        },
        redireCreate(type) {
            let url = 'add_buy';
            if (type == 1) {
                url = 'add_budgets';
            }
            let parms = { bill: this.idConvert, supplier: this.idUser };
            console.log(parms);
            this.$router.push({ name: url, query: parms });
        },
        convertBill(bill) {
			let date = this.moment(bill.created_at).format("YYYY-MM-DD");
            let billD = {
                'idConvert': bill.id,
                'idUser': bill.user_id,
                'date': date,
				'amount': bill.amount,
            }
            localStorage.setItem('bill_data', JSON.stringify(billD));
            this.$router.push('/checks');
        },
        newDepartment(account = {}) {
            Event.$emit('event-show-addBill', account);
        },
        newPay(account = {}) {
            Event.$emit('event-show-addPays', account);
        },
        deleted: function (id) {
            let tit = 'Eliminar factura';
            let txt = 'Seguro que quiere eliminar la factura';

            this.$swal({
                title: tit,
                text: txt,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.callDeleted(id);
                }
            });
        },
        async callDeleted(id) {
            let _endpoint = '/bills/' + id;
            try {
                let response = await request.destroy(_endpoint).then(function (response) { return response; });
                this.list();
                this.successNotification(response.data.message);
            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        },
        showImport() {
            this.$modal.show('example');
        },
        async list(page = 1) {
            let service = request;
            let search = this.search;
            let _endpoint = '/bills';
            try {
                let { data } = await service.list(_endpoint, page, search)
                if (data) {
                    console.log(data);
                    this.bills = data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>

<style scoped>
.pagination {
    margin-bottom: 0;
}
</style>

<template>
    <div class="row">
        <div class="col-12">
            <h5 class="mb-3"> Empresas asignadas para emitir facturas:</h5>
            <div class="table-responsive">
                <table class="table table-bordered text-center text-sm">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Monto</th>
                            <th>Tipo</th>
                            <th>Empresa</th>
                            <th>Opciones</th>
                        </tr>
                    </thead>
                    <tbody v-if="bills">
                        <tr v-for="(bill, index) in bills.data" :key="index">
                            <td>{{ bill.id }}</td>
                            <td>{{ bill.name }}</td>
                            <td>{{ bill.amount | toCurrency }}</td>
                            <td>{{ bill.type.name }}</td>
                            <td>
                                <span v-if="bill.company">{{ bill.company.name }}</span>
                                <span v-else> Sin empresa</span>
                            </td>
                            <td class="text-center">
                                <a v-tooltip.bottom-start="'Tooltip on bottom'" v-if="bill.file" target="_blank"
                                    class="btn m-1 btn-outline-secondary btn-sm" :href="`${globalUrlFiles}${bill.file}`">
                                    <i class="fas fa-file-pdf "> </i> Archivo PDF
                                </a>
                                <a v-tooltip.bottom-start="'Tooltip on bottom'" v-if="bill.xml" target="_blank"
                                    class="btn m-1 btn-outline-secondary btn-sm" :href="`${globalUrlFiles}${bill.xml}`">
                                    <i class="fas fa-file "> </i> Archivo XML
                                </a>
                                <button @click="newDepartment(bill)" v-tooltip.bottom-start="'Tooltip on bottom'"
                                    v-can="'edit_bill'" class="btn m-1 btn-outline-primary btn-sm">
                                    <i class="fas fa-edit "> </i> Editar
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td align="center" colspan="5">Sin resultados.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-12">
            <hr>
            <h5> Empresas </h5>
            <div class="row mt-3">
                <div class="col-md-6" v-for="(company, index) in catalogue.companies_users" :key="index">
                    <div class="card" v-if="company.bank_accounts.length == 0">
                        <div class="card-body p-1">
                            <h6>{{ company.name }}</h6>
                            <hr>
                            <H4>Sin cuentas de banco</H4>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-md-12" v-for="(bank_accounts, index) in company.bank_accounts" :key="index">
                            <div class="card">
                                <div class="card-body p-1">
                                    <h6>{{ company.name }}</h6>
                                    <a v-if="bank_accounts.file" class="btn btn-info float-right btn-sm"
                                        style="margin-top: -30px;" target="_blank"
                                        :href="`${globalUrlFiles}${bank_accounts.file}`">
                                        <i class="fa-solid fa-file"></i>
                                    </a>
                                    <hr>
                                    <div>
                                        <p class="mb-1"><b>Banco :</b> {{ bank_accounts.bank.name }}</p>
                                        <p class="mb-1"><b>Número de cuenta :</b> {{ bank_accounts.number }}</p>
                                        <p class="mb-1"><b>No. Cta. Clave :</b> {{ bank_accounts.number_bis }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>