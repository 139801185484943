<script>
import { mapActions } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
	props: ['building_id', 'tenants', 'departments', 'documents'],
	data() {
		return {
			catalogue: new Catalogue(),
			id: '',
			emailExist: false,
			depExist: false,
			name: '',
			email: '',
			description: '',
			number: '',
			level: '',
			area: '',
			rent: '',
			date_payment: '',
			departament_id: '',
			tab_active: 'store',
			department: {},
			form: {
				document_id: '',
				department_id: '',
				comments: ''
			}
		}
	},
	methods: {
		validateEmail(event) {
			let user = this.tenants.find((tenant) => tenant.email == event.target.value);
			if (user) {
				this.emailExist = true;
			} else {
				this.emailExist = false;
			}
		},
		validateDepartment(event) {
			let dep = this.departments.find((d) => d.number == event.target.value);
			if (dep) {
				this.depExist = true;
			} else {
				this.depExist = false;
			}
		},
		onFileSelected: function (event) {
			let mimes = ['application/pdf', 'image/png', 'image/jpeg'];
			let is_permited = mimes.find((mime) => event.target.files[0].type == mime);
			if (typeof is_permited == 'undefined') {
				this.$toastr.e('Tipo de archivo no permitido', 'Hubo un error', { "closeButton": true });
				event.target.value = '';
			} else {
				if (event.target.files[0].size < 1000000) {
					this.form.file = event.target.files[0];
				} else {
					this.$toastr.e('El archivo es demasiado grande, no debe pesar mas de 1MB', 'Hubo un error', { "closeButton": true });
					event.target.value = '';
				}
			}
		},
		clearMoney(value) {
			if (value) {
				let re = /,/g;
				var resultado = value.replace(re, '');
				return resultado;
			} else {
				return 0;
			}
		},
		async saveBuildings() {
			let building = this.id;
			let _endpoint = '/departments/store';

			let form = {
				name: this.name,
				email: this.email,
				number: this.number,
				level: this.level,
				area: this.area,
				rent: this.rent,
				description: this.description,
				building_id: this.building_id
			};

			if (building != "") {
				_endpoint = `/departments/store/${building}`;
			}

			if (this.tab_active == 'store-documents' && building != "") {
				_endpoint = `/departments/store-documents/${building}`;
				form = new FormData();
				form.append('document_id', this.form.document_id);
				form.append('file', this.form.file);
				form.append('comments', this.form.comments);
			}

			try {
				let { data } = await request.postMedia(_endpoint, form)
				if (data) {

					this.successNotification(data.message);
					if(this.tab_active == 'store-documents'){
						this.resetFile();
					}else{
						this.closedModal();
					}
					this.tab_active = 'store';
					Event.$emit('event-load-building');

				}
			} catch (error) {
				console.log(error);
				this.errors = {};
				if (error.response) {
					await this.errorNotification(error);
				}
			}
		},
		closedModal() {
			this.id = '';
			this.name = '';
			this.email = '';
			this.number = '';
			this.level = '';
			this.area = '';
			this.rent = '';
			this.description = '';
			this.date_payment = '';
			this.tab_active = 'store';
			this.$modal.hide('addDepartment');
		},
		resetFile() {
			this.form.comments = '';
			this.form.department_id = '';
			this.form.document_id = '';
			this.form.file = '';
			this.$refs.file.value = null;
			this.$modal.hide('addDepartment');
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	mounted() {
		this.catalogue.get_type_documents();
	},
	created() {
		Event.$on("event-show-addDepartment", (department) => {
			this.department = department;
			this.id = department == null ? '' : department.id;
			this.name = department == null ? '' : department.tenant.name;
			this.email = department == null ? '' : department.tenant.email;
			this.number = department == null ? '' : department.number;
			this.level = department == null ? '' : department.level;
			this.area = department == null ? '' : department.area;
			this.rent = department == null ? '' : department.rent;
			this.description = department == null ? '' : department.description;
			this.date_payment = department == null ? '' : new Date(`${department.date_payment}T00:00-0600`);
			this.$modal.show('addDepartment');
		});
	}
}
</script>
<template>
	<modal name="addDepartment" :width="'70%'" :height="'auto'">
		<button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
		<form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
			<div class="modal-header">
				<!-- <h5 class="modal-title" id="exampleModalLabel">Departamento... {{department ? department.number:''}}</h5> -->
				<h5 class="modal-title" id="exampleModalLabel">Ingresos</h5>
			</div>
			<div class="modal-body">
				<input type="hidden" name="building_id" :value="(department ? department.building_id : building_id)">
				<div class="row">

					<div class="col-md-12">

						<ul class="nav nav-tabs">
							<li class="nav-item" style="width: 49.5%;">
								<a data-toggle="tab" href="#tab-department-general" @click="tab_active = 'store'"
									class="active nav-link">Datos generales</a>
							</li>
							<li class="nav-item" style="width: 49.5%;">
								<a data-toggle="tab" href="#tab-department-docs" @click="tab_active = 'store-documents'"
									class="nav-link">Documentos</a>
							</li>
						</ul>

						<div class="tab-content">
							<div class="tab-pane active" id="tab-department-general" role="tabpanel">
								<div v-if="tab_active == 'store'" class="row">
									<!-- <div class="col-md-6"> -->
									<!-- <div class="form-group">
													<label for="number" class="control-label">Número:</label>
													<input required name="number" type="text" class="form-control" autocomplete="off" @input="validateDepartment($event)" v-model="number">
													<small v-if="depExist" class="text-danger">Este departamento ya esta registrado</small>
												</div> -->

									<!-- <div class="form-group">
													<label for="level" class="control-label">Piso:</label>
													<input required name="level" type="number" class="form-control" v-model="level">
												</div> -->

									<!-- <div class="form-group">
													<label for="area" class="control-label">Renta:</label>
													<input type="hidden" name="rent" :value="clearMoney(rent)">
													<input required name="renta" type="text" v-money="$root.money" class="form-control" v-model.lazy="rent">
												</div> -->

									<!-- <div class="form-group">
													<label for="area" class="control-label">Fecha de inicio:</label>
													<date-picker :popup-style="{'z-index':999999}" v-model="date_payment" input-name="date_payment" type="date" lang="es" input-class="form-control" format="YYYY-MM-DD" confirm confirm-text="Aplicar"/>
												</div> -->

									<!-- <div class="form-group">
													<label for="area" class="control-label">Area:</label>
													<input required name="area" type="text" class="form-control" v-model="area">
												</div> -->


									<!-- </div> -->
									<div class="col-md-12">
										<div class="form-group">
											<label for="responsable" class="control-label">Responsable:</label>
											<input required name="name" type="text" class="form-control" v-model="name">
										</div>

										<div class="form-group">
											<label for="responsable" class="control-label">Correo:</label>
											<input required name="email" type="email" class="form-control"
												autocomplete="off" v-model="email">
											<div v-if="emailExist" class="mt-3 alert alert-warning">
												<p>Ya existe un inquilo en la plataforma con este correo, esta
													acción asiganara como responsable de este departamento al mismo
													inquilino.</p>
												<p>El nombre se actualizara conforme este formulario.</p>
											</div>
										</div>

										<div class="form-group">
											<label for="responsable" class="control-label">Descripción:</label>
											<textarea class="form-control" name="description"
												v-model="description"> </textarea>
										</div>

									</div>
								</div>
							</div>
							<div v-if="department" class="tab-pane" id="tab-department-docs" role="tabpanel">
								<div v-if="tab_active == 'store-documents'" class="row">
									<div class="col-md-6">
										<div class="row">
											<div class="col-md-12">
												<div class="form-group">
													<label for="number" class="control-label">Tipo
														documento:</label>
													<select required="" name="document_id" class="form-control"
														v-model="form.document_id">
														<option value="">Selecciona un tipo de documento</option>
														<option v-for="(doc, index) in catalogue.type_documents"
															:key="index" :value="doc.id"> {{ doc.name }} </option>
													</select>
												</div>
											</div>
											<div class="col-md-12">
												<div class="form-group">
													<label for="number" class="control-label">Documento:</label>
													<input type="file" required="" class="form-control" name="file"
														ref="file" @change="onFileSelected">
												</div>
											</div>
											<div class="col-md-12">
												<div class="form-group">
													<label for="comments" class="control-label">Comentarios:</label>
													<textarea class="form-control" name="comments" v-model="form.comments"
														rows="3"></textarea>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<table class="mb-0 table table-striped">
											<thead class="bg-navy text-white">
												<tr>
													<th>Documento</th>
													<th style="width: 70px;text-align: center"><i class="fa fa-cogs"></i>
													</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(doc, index) in department.documents" :key="index">
													<td>{{ doc.name }}</td>
													<td>
														<a target="_blank"
															:href="`${globalUrlFiles}/documents/${doc.pivot.file}`"
															class="btn btn-xs bg-navy text-white">
															<i class="fa fa-eye"></i>
														</a>
													</td>
												</tr>

												<tr v-if="department.documents.length == 0">
													<td colspan="2">
														<h6 class="text-center">No hay documentos</h6>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" @click="closedModal()">Cerrar</button>
				<button type="submit" class="btn btn-primary" :disabled="depExist">Guardar</button>
			</div>
		</form>
	</modal>
</template>
<style scoped="">
.mx-datepicker {
	display: block;
	width: 100%;
}
</style>
