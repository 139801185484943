<template>
    <modal name="showBudget" v-bind:click-to-close="false" :width="'70%'" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="triggerSave">
            <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLabel">Departamento... {{department ? department.number:''}}</h5> -->
                <h5 class="modal-title" id="exampleModalLabel">Presupuesto</h5>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label> Propiedad </label>
                            <select disabled="disabled" type="text" class="form-control" v-model="form.building_id">
                                <option v-for="(building, index) in catalogue.buildings" :value="building.id" :key="index">
                                    {{ building.name }}</option>
                            </select>
                            <div v-if="errors && errors.building_id" class="text-danger">{{ errors.building_id[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label> Departamento </label>
                            <select disabled="disabled" v-model="form.indiviso_id" class="form-control">
                                <option value="">Sin departamento</option>
                                <option v-for="(departament, index) in catalogue.departamens_buildings" :key="index"
                                    :value="departament.id"> Número {{ departament.number }} / Piso {{ departament.level }}
                                </option>
                            </select>
                            <div v-if="errors && errors.indiviso_id" class="text-danger">{{ errors.indiviso_id[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label> Folio </label>
                            <input disabled="disabled" type="text" v-model="folio" class="form-control">
                            <div v-if="errors && errors.folio" class="text-danger">{{ errors.folio[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label> Proveedor </label>
                            <select disabled="disabled" type="text" class="form-control" v-model="form.user_id">
                                <option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">
                                    {{ user.name }} {{ user.last_name }}</option>
                            </select>
                            <div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Monto </label>
                            <money disabled="disabled" class="form-control" v-model="form.amount"></money>
                            <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Fecha</label>
                            <date-picker disabled="disabled" v-model="form.date" valueType="format"></date-picker>
                            <div v-if="errors && errors.date" class="text-danger">{{ errors.date[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Concepto </label>
                            <select disabled="disabled" type="text" class="form-control" v-model="form.concept_id">
                                <option v-for="(concept, index) in catalogue.concepts" :value="concept.id" :key="index">
                                    {{ concept.name }}</option>
                            </select>
                            <div v-if="errors && errors.concept_id" class="text-danger">{{ errors.concept_id[0] }}</div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label> Descripción </label>
                            <textarea disabled="disabled" type="text" v-model="form.description" class="form-control"></textarea>
                            <div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 ">
                        <label>Archivo</label><br>                       
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-block btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <h5 v-else>Sin Archivo</h5>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>

                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="col-md-12">
                      
                        <button @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
import request from '@/services/request'
import { mapActions } from 'vuex';
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
    components: {
        DatePicker
    },
    name: "buy-form",
    data() {
        return {
            form: {
                id: '',
                building_id: '',
                indiviso_id: '',
                folio: '',
                user_id: '',
                amount: '',
                date: '',
                concept_id: '',
                description: '',
                file: '',
                bill: '',
            },
            bank_accounts: [],
            errors: {},
            folio: '',
            // 
            file_cedula: '',
            cedularName: '',
            catalogue: new Catalogue(),
            file: '',
            fileName: ''
        }
    },
    mounted() {
        this.catalogue.get_buildings();
        this.catalogue.get_concepts();
        this.catalogue.get_users_profile(2);       
    },
    methods: {
        closedModal(){
            this.$modal.hide('showBudget');
        },        
        async get_id(id) {
            let _endpoint = `/budgets/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    this.form.id = id;
                    this.form.building_id = data.building_id;
                    this.form.indiviso_id = data.indiviso_id;
                    this.folio = data.folio;
                    this.form.user_id = data.user_id;
                    this.form.amount = data.amount;
                    this.form.concept_id = data.concept_id;
                    this.form.date = data.date;
                    this.form.description = data.description;

                    if (data.file) {
                        this.file = data.file;
                    }

                    this.catalogue.get_departamens_buildings(data.building_id);


                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    await this.errorNotification(error)
                }
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    created() {
        Event.$on("event-load-budget", (id) => {
            this.get_id(id);
            this.$modal.show('showBudget');
        });
    },
    watch: {
        'form.building_id'(value) {
            if (this.form.id == '') {
                console.log(value);
                this.catalogue.get_folio_budgets(value);
                this.catalogue.get_departamens_buildings(value);
            }
        },
        'catalogue.folio_budgets'() {
            this.folio = this.catalogue.folio_budgets.folio;

        }
    },
}
</script>

<style scoped>
.pagination {
    margin-bottom: 0;
}

.mx-datepicker {
    width: 100%;
}

.fs-6 {
    font-size: 12px;
}

.custom-file-label::after {
    content: "Archivo";
}
</style>
