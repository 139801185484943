<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    components: { DatePicker },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                type_id: 1,
                user_id: '',
                concept_id: '',
                budget_id: '',
                buy_id: '',
                bill_id: '',
                date: '',
                amount: '',
                type_payment_id: '',
                description: '',
                file: ''
            },
            file: '',
            fileName: ''
        }
    },
    methods: {
        closedModal() {
            this.form.id = ''
            this.form.type_id = 1;
            this.form.user_id = '';
            this.form.concept_id = '';
            this.form.budget_id = '';
            this.form.buy_id = '';
            this.form.bill_id = '';
            this.form.date = '';
            this.form.amount = '';
            this.form.type_payment_id = '';
            this.form.description = '';
            this.form.file = '';
            this.file = '';
            this.fileName = '';
            this.$modal.hide('addChecks');
        },
        addBudget(){
            let budget = {
                'user_id' : this.form.user_id,
            };
            Event.$emit('event-show-addBudgets', budget);
        },
        addBuy(){
            let buy = {
                'user_id' : this.form.user_id,
            };
            Event.$emit('event-show-addBuys', buy);
        },
        catch_file(event) {
            if (event.target.files[0].type == "application/pdf") {
                this.form.file = event.target.files[0];
                this.fileName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },
        async saveBuildings() {
            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('type_id', this.form.type_id)
            data_form.append('user_id', this.form.user_id)
            data_form.append('concept_id', this.form.concept_id)
            data_form.append('budget_id', this.form.budget_id)
            data_form.append('buy_id', this.form.buy_id)
            data_form.append('bill_id', this.form.bill_id)
            data_form.append('amount', this.form.amount)
            data_form.append('type_payment_id', this.form.type_payment_id)
            data_form.append('description', this.form.description)
            data_form.append('file', this.form.file)

            if (this.form.date) {
                let dt = this.moment(this.form.date).format("YYYY-MM-DD");
                data_form.append('date', dt);
            }

            let _endpoint = '/checks';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/checks/${this.form.id}`;
            }
            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-checks');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        getDataBudget(){
            let budgets = this.catalogue.budgets_user;
            let budget_id = this.form.budget_id;
            let res = budgets.find((s) => s.id === budget_id);
            console.log(res.concept_id);
            this.form.concept_id = res.concept_id;
        },
        getDataBuy(){
            let buys = this.catalogue.buys_user;
            console.log(buys);
            let buy_id = this.form.buy_id;
            console.log(buy_id);
            let res = buys.find((s) => s.id === buy_id);
            console.log(res);            
            this.form.concept_id = res.concept_id;
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    mounted() {
        this.catalogue.get_users_profile(2);
        this.catalogue.get_concepts();
        this.catalogue.get_type_payments();
        let value = localStorage.getItem('bill_data');
        if (value) {
            let obj = JSON.parse(value);
            this.form.user_id = obj.idUser;
            this.form.bill_id = obj.idConvert;
            this.form.date =  this.moment(obj.date).toDate();
            this.form.amount = obj.amount;
        }
    },
    created() {
        Event.$on("event-show-addChecks", (account) => {
            if (account.id) {
                this.form.id = account.id;
                this.form.type_id = account.type_id;
                this.form.user_id = account.user_id;
                this.form.concept_id = account.concept_id;
                this.form.budget_id = account.budget_id;
                this.form.buy_id = account.buy_id;
                this.form.bill_id = account.bill_id;
                this.form.amount = account.amount;
                this.form.type_payment_id = account.type_payment_id;
                this.form.description = account.description;
                if (account.date) {
                    let dt = this.moment(account.date).toDate();
                    this.form.date = dt;
                }

                this.catalogue.get_budgets_user(this.form.user_id, this.form.concept_id);
                this.catalogue.get_buys_user(this.form.user_id, this.form.concept_id);
                this.catalogue.get_bills_user(this.form.user_id, this.form.id);
            }
            this.$modal.show('addChecks');
            localStorage.removeItem("bill_data");
        });
        Event.$on("event-load-budgets", async(budgets) => {
            if(this.form.user_id){
                await this.catalogue.get_budgets_user(this.form.user_id, '');
                this.form.budget_id = budgets.id;
                await this.getDataBudget();
            }
        });  
        Event.$on("event-load-buys", async(buys) => {
            if(this.form.user_id){
                await this.catalogue.get_buys_user(this.form.user_id, '');
                this.form.buy_id = buys.id;
                await this.getDataBuy();
            }
        });  
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
    watch: {
        'form.user_id'() {
            // this.form.budget_id = '';
            // this.form.buy_id = '';
            // this.form.bill_id = '';
            this.catalogue.get_budgets_user(this.form.user_id, '');
            this.catalogue.get_buys_user(this.form.user_id, '');
            this.catalogue.get_bills_user(this.form.user_id, this.form.id);
        },
        'form.type_id'() {
            // this.form.budget_id = '';
            // this.form.buy_id = '';
            this.catalogue.get_budgets_user(this.form.user_id, '');
            this.catalogue.get_buys_user(this.form.user_id, '');
        },
        'form.budget_id'() {
           this.getDataBudget();
        },
        'form.buy_id'() {
            this.getDataBuy();
           
        }
        // 'form.concept_id'() {
        //     // this.form.budget_id = '';
        //     // this.form.buy_id = '';
        //     this.catalogue.get_budgets_user(this.form.user_id, this.form.concept_id);
        //     this.catalogue.get_buys_user(this.form.user_id, this.form.concept_id);
        // },
    }    
}
</script>
<template>
    <modal name="addChecks" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información Cheque</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <label>Tipo</label>
                        <select type="text" :disabled="!prop_edit" class="form-control" v-model="form.type_id">
                            <option :value="1">Presupuestos</option>
                            <option :value="2">Compra</option>
                        </select>
                        <div v-if="errors && errors.type_id" class="text-danger">{{ errors.type_id[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Proveedor</label>
                        <select type="text" :disabled="!prop_edit" class="form-control" v-model="form.user_id">
                            <option v-for="(user, index_user) in catalogue.users_profile" :value="user.id"
                                :key="index_user">{{
                                    user.name }} {{ user.last_name }}</option>
                        </select>
                        <div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
                    </div>

                    <div class="col-md-8 mt-3" v-if="form.type_id == 1">
                        <label>Presupuesto</label>
                        <div class="input-group mb-3">
                            <select type="text" :disabled="!prop_edit" required class="form-control"
                                v-model="form.budget_id">
                                <option v-for="(budget, index_budeget) in catalogue.budgets_user" :value="budget.id"
                                    :key="index_budeget">
                                    {{ budget.folio }},
                                    {{ budget.building.name }},
                                    $ {{ budget.amount }}
                                </option>
                                <option value="" v-if="catalogue.budgets_user.length == 0"> Sin resultados</option>
                            </select>
                            <button class="btn ml-3 btn-outline-success" @click="addBudget"> <i class="fa fa-plus"></i> </button>
                        </div>
                        <div class="text-sm text-info" v-if="form.user_id && catalogue.budgets_user.length == 0">Sin
                            presupuesto, necesita ingresar uno a este proveedor</div>
                        <div v-if="errors && errors.budget_id" class="text-danger">{{ errors.budget_id[0] }}</div>
                    </div>

                    <div class="col-md-8 mt-3" v-if="form.type_id == 2">
                        <label>Compra</label>
                        <div class="input-group mb-3">
                            <select type="text" :disabled="!prop_edit" required class="form-control" v-model="form.buy_id">
                                <option v-for="(buy, index_buy) in catalogue.buys_user" :value="buy.id" :key="index_buy">
                                    {{ buy.building.name }},
                                    {{ buy.payment_period.name }}
                                    {{ buy.amount }}
                                </option>
                                <option value="" v-if="catalogue.buys_user.length == 0"> Sin resultados</option>
                            </select>
                            <button class="btn ml-3 btn-outline-success" @click="addBuy"> <i class="fa fa-plus"></i> </button>
                        </div>
                        <div class="text-sm text-info" v-if="form.user_id && catalogue.buys_user.length == 0">Sin compras,
                            necesita ingresar uno a este proveedor</div>
                        <div v-if="errors && errors.buy_id" class="text-danger">{{ errors.buy_id[0] }}</div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <label>Concepto</label>
                        <select type="text" :disabled="true" class="form-control" v-model="form.concept_id">
                            <option v-for="(concept, index_concept) in catalogue.concepts" :value="concept.id"
                                :key="index_concept">{{
                                    concept.name }}</option>
                        </select>
                        <div v-if="errors && errors.concept_id" class="text-danger">{{ errors.concept_id[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Factura</label>
                        <select type="text" :disabled="!prop_edit" class="form-control" v-model="form.bill_id">
                            <option v-if="form.id == ''" value="">Crear Factura</option>
                            <option v-for="(bill, index_bill) in catalogue.bills_user" :value="bill.id" :key="index_bill">{{
                                bill.name }}</option>
                            <option value="" v-if="catalogue.bills_user.length == 0 && form.id != ''"> Sin resultados
                            </option>
                        </select>
                        <span v-if="form.id == ''" class="text-info text-sm">DEJE EN BALCO PARA QUE EL PROVEEDOR
                            POSTERIORMENTE SUBA SU FACTURA</span>
                        <div v-if="errors && errors.bill_id" class="text-danger">{{ errors.bill_id[0] }}</div>
                    </div>


                    <div class="col-md-4 mt-3">
                        <label>Fecha</label><br>
                        <date-picker format="YYYY-MM-DD" :disabled="!prop_edit" v-model="form.date"></date-picker>
                        <div v-if="errors && errors.date" class="text-danger">{{ errors.date[0] }}</div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <label>Monto</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.amount"></money>
                        <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                    </div>
                    <div class="col-md-4 mt-3">
                        <label>Tipo de saldo</label>
                        <select type="text" :disabled="!prop_edit" class="form-control" v-model="form.type_payment_id">
                            <option v-for="(type_payment, index_type_payment) in catalogue.type_payments"
                                :value="type_payment.id" :key="index_type_payment">{{
                                    type_payment.name }}</option>
                        </select>
                        <div v-if="errors && errors.type_payment_id" class="text-danger">{{ errors.type_payment_id[0] }}
                        </div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Descripción</label>
                        <textarea type="text" :disabled="!prop_edit" class="form-control"
                            v-model="form.description"></textarea>
                        <div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Archivo</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" :disabled="!prop_edit" class="custom-file-input"
                                :class="file != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>

                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>