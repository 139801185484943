<template>
	<div class="row bg align-items-center justify-content-center">
		<div class="login-box">
			<div class="card card-outline card-secondary" style=" background-color: #dedad98f;">
				<div class="card-header text-center">
					<img src="@/assets/img/logo.png" alt="Logo" width="100px;">
				</div>
				<div class="card-body">
					<h4 style="color: #229d86;" class="login-box-msg">Registrarse</h4>
					<form @submit.prevent="triggerRegister">
						<div class="col-12 mb-3">
							<div class="input-group ">
								<div class="input-group-append">
									<div class="input-group-text">
										<span class="fas fa-user"></span>
									</div>
								</div>
								<input type="text" v-model="form.name" class="form-control" placeholder="Nombres"
									:class="{ 'is-invalid': submitted && !form.name }" />
							</div>
							<div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
						</div>
						<div class="col-12 mb-3">
							<div class="input-group ">
								<div class="input-group-append">
									<div class="input-group-text">
										<span class="fas fa-user"></span>
									</div>
								</div>
								<input type="text" v-model="form.last_name" class="form-control" placeholder="Apellidos"
									:class="{ 'is-invalid': submitted && !form.last_name }" />
							</div>
							<div v-if="errors && errors.last_name" class="text-danger">{{ errors.last_name[0] }}</div>
						</div>
						<div class="col-12 mb-3">
							<div class="input-group ">
								<div class="input-group-append">
									<div class="input-group-text">
										<span class="fas fa-envelope"></span>
									</div>
								</div>
								<input type="text" v-model="form.email" class="form-control" placeholder="Correo"
									:class="{ 'is-invalid': submitted && !form.email }" />
							</div>
							<div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
						</div>
						<div class="col-12 mb-3">
							<div class="input-group ">
								<div class="input-group-append">
									<div class="input-group-text">
										<span class="fas fa-phone"></span>
									</div>
								</div>
								<input type="text" v-model="form.phone" class="form-control" placeholder="Teléfono"
									:class="{ 'is-invalid': submitted && !form.phone }" />
							</div>
							<div v-if="errors && errors.phone" class="text-danger">{{ errors.phone[0] }}</div>
						</div>
						<div class="col-12 mb-3">
							<div class="input-group ">
								<div class="input-group-append">
									<div class="input-group-text">
										<i class="fab fa-whatsapp"></i>
									</div>
								</div>
								<input type="text" v-model="form.whatsapp" class="form-control" placeholder="Whatsapp"
									:class="{ 'is-invalid': submitted && !form.whatsapp }" />
							</div>
							<div v-if="errors && errors.whatsapp" class="text-danger">{{ errors.whatsapp[0] }}</div>
						</div>
						<hr>
						<div class="form-group text-center">
							<button class="btn btn-info" style="width: 200px;" :disabled="submitted"> Registrarse
							</button>
						</div>
						<div class="col-md-12 text-center">
							<router-link to="/login" style="font-size:14px;" href="">Iniciar sesión</router-link>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
	},
	data() {
		return {
			form: {
				name: 'Victor Hugo',
				last_name: 'Uribe',
				email: 'vic.hug.urib@gmail.com',
				phone: '',
				whatsapp: '',
			},
			errors: {},
			submitted: false
		}
	},
	methods: {
		async triggerRegister() {
			try {
				let register = await this.registerAction(this.form)
				if (register) {
					this.successNotification(register.message);
					this.$router.push('/login')
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			registerAction: 'auth/register',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
};
</script>
