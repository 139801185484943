<template>
	<div class="content-header">
		<div class="container-fluid">
			<div class="row mb-2">
				<div class="col-12">
					<h1 class="m-0">{{headerTitle}}</h1>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {  mapGetters } from 'vuex';
export default {
	name: 'app',
	computed: {
		...mapGetters({
			headerTitle: 'getHeaderTitle'
		})
	}
}
</script>
