<template>
	<div class="col-12">
		<div class="card card-outline card-info">
			<div class="card-header">
				<h3 class="card-title">Lista de persmisos</h3>
			</div>
			<div class="card-body">
				<form @submit.prevent="triggerSave">
					<div class="row">
						<div class="col-6 text-center">
							<label>Perfil :</label>
							<p v-if="form.profil">{{ form.profil.name }}</p>
							<p v-else>Sin Perfil</p>
						</div>
						<!-- <div class="col-6 text-center">
							<label>Área :</label>
							<p v-if="form.area">{{ form.area.name }}</p>
							<p v-else="form.area.name">Area</p>
						</div> -->
						<div class="col-6 text-center">
							<label>Núm. Usuarios :</label>
							<p>{{ form.users.length }}</p>
						</div>
					</div>
					<hr>
					<div class="col-12">
						<h5>Permisos</h5>
						<div class="row" v-for="(group, index) in permissions" :key="index">
							<div class="col-12">
								<div class="card">
									<div class="card-body">
										<div class="row justify-content-center">
											<div class="col-md-3 text-center" v-for="(permission, index) in group"
												:key="index">
												<label for="">{{ permission.name }}
													<input type="checkbox" :value="permission.id"
														v-model="form.permissions" class="form-control">
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>


						</div>
					</div>
					<hr>
					<div class="col-md-12">
						<button type="submit" class="btn float-right btn-outline-success">
							<i class="fas fa-save"></i> Guardar
						</button>
						<router-link to="/permissions" class="btn float-left btn-outline-warning">
							<i class="fas fa-undo-alt"></i>
							Regresar
						</router-link>
					</div>
					<br>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import request from '@/services/request'
import { mapActions } from 'vuex';

export default {
	name: "permissions-form",
	data() {
		return {
			form: {
				id: '',
				profil: {},
				area: {},
				users: [],
				permissions: []
			},
			permissions: [],
		}
	},
	methods: {
		async getProfileId(id) {
			let service = request;
			let _endpoint = '/profiles/' + id;
			try {
				let { data } = await service.get(_endpoint)
				if (data) {
					this.form.id = data.id;
					this.form.area = data.area;
					this.form.profil = data.profil;
					this.form.users = data.users;
					if (data.permissions) {
						var res = data.permissions.map(s => s.id);
						this.form.permissions = res;
					}
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		async getPermissions() {
			let service = request;
			let _endpoint = '/catalogue/permissions';
			console.log(_endpoint);
			try {
				let { data } = await service.get(_endpoint)
				if (data) {
					this.permissions = data;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		async triggerSave() {
			let _endpoint = '/profiles';
			if (this.form.id != "") {
				this.form._method = 'PUT';
				_endpoint = `/profiles/${this.form.id}`;
			}

			try {
				let { data } = await request.post(_endpoint, this.form)
				if (data) {
					this.successNotification(data.message);
					this.$router.push('/permissions');
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	mounted() {
		this.getPermissions();
		let id = this.$route.params.id;
		if (id) {
			this.getProfileId(id);
		}
	},
}
</script>
<style>

</style>
