<template>
	<div class="col-12 ">
		<div class="card card-outline card-navy">
			<div class="card-body">
				<form @submit.prevent="triggerSave">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label> Nombre </label>
								<input type="text" v-model="form.name" class="form-control">
								<div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label> Categoria </label>
								<select class="form-control" v-model="form.category_id">
									<option value="">Selecciona una opción</option>
									<option  v-for="(concept, index) in pincipals" :key="index" :value="concept.id">{{ concept.name }}</option>
								</select>
								<span class="text-info">Deja en blanco para ser categoria principal</span>
								<div v-if="errors && errors.category_id" class="text-danger">{{ errors.category_id[0] }}</div>
							</div>
						</div>
						<div class="col-12">
							<hr>
						</div>
						<div class="col-md-12">
							<button type="submit" class="btn float-right btn-outline-success">
								<i class="fas fa-save"></i> Guardar
							</button>
							<router-link to="/users" class="btn float-left btn-outline-warning">
								<i class="fas fa-undo-alt"></i>
								Regresar
							</router-link>
						</div>
					</div>
				</form>

			</div>
		</div>
	</div>
</template>

<script>
import request from '@/services/request'
import { mapActions } from 'vuex';


export default {
	name: "users-table",
	data() {
		return {
			form: {
				id: '',
				name: '',
				category_id: '',				
			},		
			pincipals : [],
			errors: {},			
		}
	},
	mounted() {
		let id = this.$route.params.id;
		if (id) {
			this.get_id(id);
		}
		this.principals();

	},
	methods: {		
		async triggerSave() {

			let form = this.form;

			let _endpoint = '/concept';
			if (this.form.id != "") {
				form._method = 'PUT';
				_endpoint = `/concept/${this.form.id}`;
			}

			try {
				let { data } = await request.postMedia(_endpoint, form)
				if (data) {
					console.log(data);
					// this.concept = data.data;
					this.successNotification(data.message);
					this.$router.push('/concepts');
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					let info_error = await this.errorNotification(error);
					if (info_error == 'Logout') {
						this.$nextTick(() => {
							location.reload()
						})
					} else {
						this.errors = info_error;
					}
				}
			}
		},
		async get_id(id) {
			let _endpoint = `/concept/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					this.form.id = id;
					this.form.name = data.name;
					this.form.category_id = data.category_id;				
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		async getProfiles() {
			let service = request;
			let _endpoint = '/catalogue/profiles';
			try {
				let { data } = await service.get(_endpoint)
				if (data) {
					this.profiles = data;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		async principals() {
			let service = request;
			let _endpoint = '/concept';
			try {
				let { data } = await service.get(_endpoint)
				if (data) {
					let catP = data;
					if(catP){
						catP.forEach(element => {							
							if(element.id != this.form.id && element.category_id != ''){
								this.pincipals.push(element);
							}							
						});

					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}	
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.mx-datepicker {
	width: 100%;
}

.fs-6 {
	font-size: 12px;
}
</style>
