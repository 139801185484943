<template>
	<div class="col-12 ">
		<div class="row">
			<div class="col-12">
				<div class="card card-outline card-navy">
					<div class="card-body">
						<form @submit.prevent="triggerSave">
							<div class="row">
								<div class="col-12 text-center">
									<h3>Imagen de perfil</h3>
									<img class="img-fluid rounded" style="width: 200px;" :src="img_perfil" alt="">
									<br>
									<br>
									<input type="file" id="file" class="inputfile" @change="csv_file">
									<label for="file"><i class="fas fa-upload"></i> Subir imagen </label>
								</div>
								<div class="col-12">
									<hr>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label> Nombre </label>
										<input type="text" v-model="form.name" class="form-control">
										<div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label> Apellidos </label>
										<input type="text" v-model="form.last_name" class="form-control">
										<div v-if="errors && errors.last_name" class="text-danger">{{ errors.last_name[0] }}
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label> Perfil </label>
										<select v-model="form.organization_charts_id" class="form-control">
											<option value="null"> Sin Perfil </option>
											<option :value="profile.id" v-for="(profile, index) in profiles" :key="index">
												<span v-if="profile.profil">{{ profile.profil.name }}</span>
												<span v-if="profile.area">- {{ profile.area.name }}</span>
											</option>
										</select>
										<div v-if="errors && errors.organization_charts_id" class="text-danger">{{
											errors.organization_charts_id[0] }}</div>
									</div>
								</div>

								<div class="col-md-4">
									<div class="form-group">
										<label> Email </label>
										<input type="mail" v-model="form.email" class="form-control">
										<div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label> Teléfono </label>
										<input type="text" v-model="form.phone" class="form-control">
										<div v-if="errors && errors.phone" class="text-danger">{{ errors.phone[0] }}</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label> WhatsApp </label>
										<input type="text" v-model="form.whathsapp" class="form-control">
										<div v-if="errors && errors.whathsapp" class="text-danger">{{ errors.whathsapp[0] }}
										</div>
									</div>
								</div>

								<!-- Proveedor -->
								<div class="col-md-4" v-if="form.organization_charts_id == 2">
									<div class="form-group">
										<label> Web </label>
										<input type="text" v-model="form.web" class="form-control">
										<div v-if="errors && errors.web" class="text-danger">{{ errors.web[0] }}</div>
									</div>
								</div>
								
								
								<div class="col-md-4" v-if="form.organization_charts_id == 2">
									<div class="form-group">
										<label> Razon social </label>
										<input type="text" v-model="form.business_name" required class="form-control">
										<div v-if="errors && errors.business_name" class="text-danger">{{ errors.business_name[0] }}</div>
									</div>
								</div>
															
								<div class="col-md-4" v-if="form.organization_charts_id == 2">
									<div class="form-group">
										<label> R.F.C </label>
										<input type="text" v-model="form.rfc" required class="form-control">
										<div v-if="errors && errors.rfc" class="text-danger">{{ errors.rfc[0] }}</div>
									</div>
								</div>

								<div class="col-md-6" v-if="form.organization_charts_id == 2">
									<div class="form-group">
										<label> Cedula fiscal </label>
										<!-- <input type="file" v-model="form.cedula" class="form-control"> -->
										<div class="custom-file" :style="file_cedula != '' ? 'width: 87%' : 'width: 100%'">
											<input type="file" class="custom-file-input"
												:class="file_cedula != '' ? 'pr-5' : ''" id="customFile" ref="file"
												@change="cedula_file">
											<label class="custom-file-label text-left" for="customFile">{{ cedularName
											}}</label>
										</div>
										<a :href="file_cedula" v-if="file_cedula != ''" target="_blank"
											style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info">
											<i class="fa-regular fa-file-pdf"></i></a>

										<div v-if="errors && errors.cedula" class="text-danger">{{ errors.cedula[0] }}
										</div>
									</div>
								</div>

								<div class="col-md-6" v-if="form.organization_charts_id == 2">
									<div class="form-group">
										<label> Opinion de cumplimiento </label>
										<!-- <input type="file" v-model="form.cedula" class="form-control"> -->
										<div class="custom-file" :style="file_compliance != '' ? 'width: 87%' : 'width: 100%'">
											<input type="file" class="custom-file-input"
												:class="file_compliance != '' ? 'pr-5' : ''" id="customFile" ref="file"
												@change="compliance_file">
											<label class="custom-file-label text-left" for="customFile">{{ complianceName
											}}</label>
										</div>
										<a :href="file_compliance" v-if="file_compliance != ''" target="_blank"
											style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info">
											<i class="fa-regular fa-file-pdf"></i></a>

										<div v-if="errors && errors.cedula" class="text-danger">{{ errors.cedula[0] }}
										</div>
									</div>
								</div>

								<div class="col-md-12 mt-2" v-if="form.organization_charts_id == 2">
									<div class="form-group">
										<label> Dirección Fiscal </label>
										<textarea v-model="form.fiscal_address" class="form-control"></textarea>
										<div v-if="errors && errors.fiscal_address" class="text-danger">{{
											errors.fiscal_address[0] }}</div>
									</div>
								</div>

								<div class="col-12" v-if="form.organization_charts_id == 2">
									<label> Giro </label>
									<multiselect v-model="form.business_type" tag-placeholder="Agregar giro"
										placeholder="Buscar o agregar una giro" label="name" track-by="id"
										:options="catalogue.business_type" :multiple="true" :taggable="true" @tag="addTag">
									</multiselect>
								</div>

								<div class="col-md-12 mt-2" v-if="form.organization_charts_id == 2">
									<div class="form-group">
										<label> Observaciones </label>
										<textarea v-model="form.observations" class="form-control"></textarea>
										<div v-if="errors && errors.observations" class="text-danger">{{
											errors.observations[0] }}</div>
									</div>
								</div>

								<div class="col-md-12" v-if="form.organization_charts_id == 2">
									<div class="form-group">
										<label> Calificación </label>
										<star-rating :star-size="'20'" v-model="form.qualification"></star-rating>
										<div v-if="errors && errors.qualification" class="text-danger">{{
											errors.qualification[0] }}</div>
									</div>
								</div>
								<!-- Fin proveedor -->
								<div class="col-md-12" v-if="form.organization_charts_id == 2" v-can="'companies_users'">
									<hr>
									<label class="control-label">Empresas:</label>
									<div class="table-responsive">
										<table class="table table-bordered text-center text-sm">
											<thead>
												<tr>
													<th>ID</th>
													<th>Nombre</th>
													<th>R.F.C</th>
													<th>Opciones</th>
												</tr>
											</thead>
											<tbody v-if="companies">
												<tr v-for="(user, index) in companies.data" :key="index">
													<td>{{ user.id }}</td>
													<td>{{ user.name }}</td>
													<td>{{ user.rfc }}</td>
													<td>
														<input type="checkbox" class="form-control form-control-sm"
															:value="user.id" v-model="form.companies">
													</td>
												</tr>
											</tbody>
											<tbody v-else>
												<tr>
													<td align="center" colspan="5">Sin resultados.</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>


								<div class="col-12">
									<hr>
								</div>
								<div class="col-md-12">
									<button type="submit" class="btn float-right btn-outline-success">
										<i class="fas fa-save"></i> Guardar
									</button>
									<router-link to="/users" class="btn float-left btn-outline-warning">
										<i class="fas fa-undo-alt"></i>
										Regresar
									</router-link>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div class="card card-primary card-outline card-outline-tabs">
					<div class="card-header p-0 pt-3 border-bottom-0">
						<ul class="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">						
							<li class="nav-item">
								<a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill"
									href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home"
									aria-selected="true">Cuentas Bancarias</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill"
									href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile"
									aria-selected="false">Contactos</a>
							</li>
						</ul>
					</div>
					<div class="card-body">
						<div class="tab-content" id="custom-tabs-four-tabContent">							
							<div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel"
								aria-labelledby="custom-tabs-four-home-tab">
								<ListBankAccount/>
							</div>
							<div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel"
								aria-labelledby="custom-tabs-four-profile-tab">
								<ListContact/>
							</div>
						</div>
					</div>

				</div>
			</div>

		</div>
	</div>
</template>

<script>
import request from '@/services/request'
import { mapActions } from 'vuex';
import Multiselect from 'vue-multiselect'
import { StarRating } from 'vue-rate-it';
import Catalogue from '@/services/catalogue';

import ListBankAccount from '@/components/bank_account_provider/ListBankAccoutProviderComponent.vue';
import ListContact from '@/components/contanct_provider/ListContanctProviderComponent.vue';

export default {
	name: "users-form",
	components: {
		Multiselect,
		StarRating,
		ListBankAccount,
		ListContact
	},
	data() {
		return {
			form: {
				id: '',
				name: '',
				last_name: '',
				img_perfil: '',
				phone: '',
				whathsapp: '',
				organization_charts_id: '',
				email: '',
				birthday: '',
				admission_date: '',
				user_id: '',
				cedula: '',
				compliance_opinion: '',
				observations: '',
				web: '',
				qualification: '',
				type_id: '',
				rfc:'',
				business_name:'',
				fiscal_address:'',
				companies: [],
				business_type: [],
			},
			companies: {},
			cedularName: null,
			file_cedula: '',
			complianceName:null,
			file_compliance:'',
			showUsers: false,
			area: '',
			profiles: [],
			img_perfil: require('@/assets/img/profile.png'),
			errors: {},
			action: '/admin/user',
			catalogue: new Catalogue(),
			option: {
				penColor: "rgb(0, 0, 0)",
				backgroundColor: "rgb(255,255,255)"
			},
		}
	},
	mounted() {
		this.getProfiles();
		this.listCompanies();
		this.catalogue.get_business_type();
		this.catalogue.get_provider_types();
		let id = this.$route.params.id;
		if (id) {
			this.get_id(id);
		}
	},
	methods: {
		async addTag(newTag) {
			let _endpoint = '/business_type'
			try {
				let { data } = await request.post(_endpoint, { name: newTag })
				if (data) {
					this.catalogue.get_business_type();
					this.form.business_type.push(data);
				}
			} catch (error) {
				console.log(error);
			}
		},
		async listCompanies(page = 1) {
			let search = {};
			let _endpoint = '/companies';
			try {
				let { data } = await request.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.companies = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		csv_file(event) {
			console.log(event.target.files[0].type);
			if (event.target.files[0].type == "image/png" ||
				event.target.files[0].type == "image/jpeg") {
				this.form.img_perfil = event.target.files[0];
				this.img_perfil = URL.createObjectURL(event.target.files[0]);
			} else {
				this.$swal({
					icon: 'error',
					title: 'Error tipo de archivo',
					text: 'Solo se puede cargar archivos PNG , JPGE',
				});
			}
		},
		cedula_file(event) {
			if (event.target.files[0].type == "application/pdf") {
				this.form.cedula = event.target.files[0];
				this.cedularName = event.target.files[0].name;
			} else {
				this.$swal({
					icon: 'error',
					title: 'Error tipo de archivo',
					text: 'Solo se puede cargar archivos PDF',
				});
			}
		},
		compliance_file(event) {
			if (event.target.files[0].type == "application/pdf") {
				this.form.compliance_opinion = event.target.files[0];
				this.complianceName = event.target.files[0].name;
			} else {
				this.$swal({
					icon: 'error',
					title: 'Error tipo de archivo',
					text: 'Solo se puede cargar archivos PDF',
				});
			}
		},
		async triggerSave() {

			const data_form = new FormData();
			data_form.append('id', this.form.id);
			data_form.append('name', this.form.name);
			data_form.append('last_name', this.form.last_name);
			data_form.append('email', this.form.email);
			data_form.append('phone', this.form.phone);
			data_form.append('organization_charts_id', this.form.organization_charts_id);
			data_form.append('img_perfil', this.form.img_perfil);
			data_form.append('whathsapp', this.form.whathsapp);
			data_form.append('birthday', this.form.birthday);
			data_form.append('admission_date', this.form.admission_date);
			data_form.append('user_id', this.form.user_id);
			data_form.append('cedula', this.form.cedula);
			data_form.append('web', this.form.web);
			data_form.append('companies', this.form.companies);
			data_form.append('observations', this.form.observations);
			data_form.append('qualification', this.form.qualification);
			data_form.append('type_id', this.form.type_id);
			data_form.append('rfc', this.form.rfc);
			data_form.append('compliance_opinion', this.form.compliance_opinion);
			data_form.append('business_name', this.form.business_name);
			data_form.append('fiscal_address', this.form.fiscal_address);
			data_form.append('business_type', this.form.business_type.map(a => a.id));


			let _endpoint = '/users';
			if (this.form.id != "") {
				data_form.append('_method', 'PUT');
				_endpoint = `/users/${this.form.id}`;
			}

			try {
				let { data } = await request.postMedia(_endpoint, data_form)
				if (data) {
					console.log(data);
					// this.users = data.data;
					this.successNotification(data.message);
					this.$router.push('/users');
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					let info_error = await this.errorNotification(error);
					if (info_error == 'Logout') {
						this.$nextTick(() => {
							location.reload()
						})
					} else {
						this.errors = info_error;
					}
				}
			}
		},
		async get_id(id) {
			let _endpoint = `/users/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					this.form.id = id;
					this.form.name = data.name;
					this.form.last_name = data.last_name;
					this.form.img_perfil = data.img_perfil;
					this.form.phone = data.phone;
					this.form.whathsapp = data.whathsapp;
					this.form.email = data.email;
					this.form.organization_charts_id = data.organization_charts_id;
					this.form.birthday = data.birthday;
					this.form.admission_date = data.admission_date;
					this.form.user_id = data.user_id;
					this.form.cedula = data.cedula;
					this.form.web = data.web;
					this.form.business_type = data.business_types;
					this.form.observations = data.observations;
					this.form.qualification = data.qualification;
					this.form.type_id = data.type_id;
					this.form.rfc = data.rfc;
					this.form.fiscal_address = data.fiscal_address;

					this.form.business_name = data.business_name;
					if (data.profile_picture) {
						this.img_perfil = this.globalUrlFiles + data.profile_picture;
					}
					if (data.cedula) {
						this.file_cedula = this.globalUrlFiles + data.cedula;
					}

					if (data.compliance_opinion) {
						this.file_compliance = this.globalUrlFiles + data.compliance_opinion;
					}

					let companies = [];
					data.companies.forEach(function (valor) {
						companies.push(valor.id);
					});

					this.form.companies = companies;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		async getProfiles() {
			let service = request;
			let _endpoint = '/catalogue/profiles';
			try {
				let { data } = await service.get(_endpoint)
				if (data) {
					this.profiles = data;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.pagination {
	margin-bottom: 0;
}

.mx-datepicker {
	width: 100%;
}

.fs-6 {
	font-size: 12px;
}

.custom-file-label::after {
	content: "Archivo";
}
</style>
