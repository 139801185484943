<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                name: '',
                phone: '',
                cell_phone: '',
                email: '',
                post: '',
                note: ''
            }
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.name = '';
            this.form.phone = '';
            this.form.email = '';
            this.form.cell_phone = '';
            this.form.post = '';
            this.form.note = '';
            this.$modal.hide('addContProvider');
        },
        async saveBuildings() {
            let form = this.form;
            console.log(form);
            let _endpoint = `/${this.id_provider}/contanct_provider`;
            if (form.id != "") {
                form._method = 'PUT';
                _endpoint = `/${this.id_provider}/contanct_provider/${this.form.id}`;
            }
            try {
                let { data } = await request.postMedia(_endpoint, form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-contanct-provider');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    async mounted() {
        await this.catalogue.get_banks();
        await this.catalogue.get_type_account();
        let id = this.$route.params.id;
        if (id) {
            this.id_provider = id;
        }
        if (this.authUser.organization_charts_id == 2) {
            this.id_provider = this.authUser.id;
        }
    },
    created() {
        Event.$on("event-show-addContProvider", (account) => {
            console.log(account);
            if (account.id) {
                this.form.id = account.id;
                this.form.name = account.name;
                this.form.phone = account.phone;
                this.form.cell_phone = account.cell_phone;
                this.form.email = account.email;
                this.form.post = account.post;
                this.form.note = account.note;
            }
            this.$modal.show('addContProvider');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>
<template>
    <modal name="addContProvider" :width="'80%'" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Contacto</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <label>Nombre</label>
                        <input type="text" class="form-control" v-model="form.name">
                        <div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Teléfono oficina</label>
                        <input type="text" class="form-control" v-model="form.phone">
                        <div v-if="errors && errors.phone" class="text-danger">{{ errors.phone[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Teléfono celular</label>
                        <input type="text" class="form-control" v-model="form.cell_phone">                       
                        <div v-if="errors && errors.cell_phone" class="text-danger">{{ errors.cell_phone[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Correo</label>
                        <input type="email" class="form-control" v-model="form.email">
                        <div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Cargo</label>
                        <input type="text" class="form-control" v-model="form.post">                      
                        <div v-if="errors && errors.post" class="text-danger">{{ errors.post[0] }}</div>
                    </div>
                       <div class="col-md-12 mt-3">
                        <label>Notas</label>
                        <textarea  class="form-control" v-model="form.note"></textarea>
                        <div v-if="errors && errors.note" class="text-danger">{{ errors.note[0] }}</div>
                    </div>

                    <div class="col-md-12">
                        <hr>
                        <button type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>