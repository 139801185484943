export default {
    user: localStorage.getItem('user') || null,
    routeChange: false,
    header_title: null,
    sidebar_collapse: localStorage.getItem('collapse') || '',
    type_view: localStorage.getItem('typeView') || 'list' ,
    show_type_view: localStorage.getItem('showTypeView') || false ,
    lang: localStorage.getItem('lang') || 'es',
    optionPages: [25, 50, 100, 200, 500],
    showPages: localStorage.getItem('showPages') || 25,
	loadingScreen: false,
}
