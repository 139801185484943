<template>
	<div class="col-12">
		<div class="card card-outline card-secondary">
			<div class="card-header">
				<h3 class="card-title">Lista de Propiedades</h3>
				<div class="card-tools">					
					<router-link :to="`/buildings/form`" v-can="'add_buildings'" class="btn btn-sm btn-outline-success">
						<i class="fa fa-plus"></i> Propiedad
					</router-link>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>Folio</th>
								<th>Folio Interno</th>
								<th>Nombre</th>
								<th>Dirección</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="buildins">
							<tr v-for="(buildin, index) in buildins.data" :key="index">
								<td>{{ buildin.folio }}</td>
								<td>{{ buildin.folio_int }}</td>
								<td>{{ buildin.name }}</td>
								<td>{{ buildin.address }}</td>								
								<td>
									<router-link :to="`/buildings/form/${buildin.id}`" v-can="'edit_buildings'" class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</router-link>
									<button type="button" @click="deleted(buildin.id)" v-can="'deleted_buildings'" class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!-- <pagination align="center" :data="buildins" :limit="8" @pagination-change-page="list">
            <span slot="prev-nav">&lt; Anterior</span>
            <span slot="next-nav">Siguiente &gt;</span>
        </pagination> -->
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex';
// import pagination from 'laravel-vue-pagination'
import request from '@/services/request'
export default {
	name: "buildins-table",
	// components: {
	// 	pagination,
	// },
	data() {
		return {
			buildins: {},
			search: '',
		}
	},
	mounted() {
		this.list();
		this.setHeaderTitle('Propiedades');
	},
	created() {
			Event.$on("event-load-buildins", () => {
					this.list();
			});
	},
	methods: {
		deleted: function (id) {
			let tit = 'Eliminar usuario';
			let txt = 'Seguro que quiere eliminar al usuario';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/buildings/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response;});
				this.list();
				this.successNotification(response.data.message);			
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport(){
			this.$modal.show('example');
		},
		async list(page = 1) {
			let service = request;
			let search = this.search;
			let _endpoint = '/buildings';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.buildins = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
