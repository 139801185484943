<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                check_id: '',
                date: '',
                amount: '',
                description: '',
                file: '',
                receipt: '',
            },
            file: '',
            fileName: '',
            receipt:'',
            receiptName: ''
        }
    },
    methods: {
        closedModal() {
            this.form.id = ''
            this.form.check_id = '';
            this.form.date = '';
            this.form.amount = '';
            this.form.description = '';
            this.form.file = '';
            this.form.receipt = '';
            this.file = '';
            this.fileName = '';
            this.receipt = '';
            this.receiptName = '';
            this.$modal.hide('addPays');
        },
        catch_file(event) {
            this.form.receipt = event.target.files[0];
            this.receiptName = event.target.files[0].name;
        },
        async saveBuildings() {
            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('check_id', this.form.check_id)
            data_form.append('date', this.form.date)
            data_form.append('amount', this.form.amount)
            data_form.append('description', this.form.description)
            data_form.append('file', this.form.file)            
            data_form.append('receipt', this.form.receipt)            

            if (this.form.date) {
                let dt = this.moment(this.form.date).format("YYYY-MM-DD");
                data_form.append('date', dt);
            }

            let _endpoint = '/pays';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/pays/${this.form.id}`;
            }

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-bills');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-addPays", ( account) => {
            if (account) {
                this.form.check_id = account.check_id;
                this.form.id = account.id;
                this.form.amount = account.amount;
                this.form.description = account.description;              
                if (account.date) {
                    let dt = this.moment(account.date).toDate();
                    this.form.date = dt;
                }
                if (account.file) {
                    this.file = account.file;
                }
                if (account.receipt) {
                    this.receipt = account.receipt;
                }
            }
            this.$modal.show('addPays');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    }   
}
</script>
<template>
    <modal name="addPays" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información Pago</h5>
            </div>
            <div class="modal-body">
                <div class="row">                                   
                    <div class="col-md-4 mt-3">
                        <label>Fecha</label><br>
                        {{ form.date | toDateTime }}
                    </div>
                    <div class="col-md-4 mt-3">
                        <label>Monto</label><br>
                       {{ form.amount |  toCurrency }}
                    </div>     
                    <div class="col-md-4 mt-3">
                        <label>Archivo</label><br>                     
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-block btn-info"> <i
                                class="fa-regular fa-file-pdf pr-2"></i> Descargar 
                        </a>                     
                    </div>             
                    <div class="col-md-12 mt-3">
                        <label>Descripción</label><br>
                        {{ form.description }}                        
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Archivo</label><br>
                        <div class="custom-file" :style="receipt != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" :disabled="!prop_edit" class="custom-file-input"
                                :class="receipt != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ receiptName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${receipt}`" v-if="receipt != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.receipt" class="text-danger">{{ errors.receipt[0] }}</div>

                    </div>


                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>