<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de cheques por pagar</h3>
				<div class="card-tools">
					<button @click="formData()" v-can="'add_checks'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> cheque
					</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search.name" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="row">

					<div class="col-md-12">
						<ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
							<li class="nav-item">
								<a class="nav-link active" @click="search.authorized = 1" data-toggle="pill" role="tab"
									aria-selected="true">Por validar</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" @click="search.authorized = 2" data-toggle="pill" role="tab"
									aria-selected="false">Validados</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th>Tipo</th>
								<th>Proveedor</th>
								<th>Concepto</th>
								<th>Monto</th>
								<th>Fecha</th>
								<th>Tipo de saldo</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="checks.data.length > 0">
							<tr :class="buy.authorization_user_id ? 'bg-info' : ''" v-for="(buy, index) in checks.data"
								:key="index">
								<td>{{ buy.id }}</td>
								<td>
									<span v-if="buy.type_id == 1"> Presupuesto </span>
									<span v-else> Compra </span>
								</td>
								<td>
									<span v-if="buy.provider"> {{ buy.provider.name }} {{ buy.provider.last_name }}</span>
									<span v-else>Sin proveedor</span>
								</td>
								<td>
									<span v-if="buy.concept"> {{ buy.concept.name }}</span>
									<span v-else>Sin Concepto</span>
								</td>
								<td>
									{{ buy.date }}
								</td>
								<td>
									{{ buy.amount | toCurrency }}
								</td>
								<td>
									<span v-if="buy.type_payment"> {{ buy.type_payment.name }}</span>
									<span v-else>Sin Tipo de Saldo</span>
								</td>
								<td>
									<button v-if="buy.pay" @click="formListPays(buy)" v-can="'edit_checks'"
										class="btn btn-outline-warning m-1 btn-sm">
										<i class="fa-solid fa-hand-holding-dollar"> </i> Información de pagos
									</button>
									<button @click="formData(buy)" v-can="'edit_checks'"
										class="btn btn-outline-primary m-1 btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</button>
									<button v-if="!buy.pay" type="button" @click="deleted(buy.id)" v-can="'deleted_checks'"
										class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>
									<button v-if="!buy.authorization_user_id" type="button" @click="approve(buy.id)"
										v-can="'approve_checks'" class="btn-outline-warning btn m-1 btn-sm ">
										<i class="fa fa-check"></i> Aprobar
									</button>

									<button v-if="buy.type_id == 1" @click="showBudget(buy.budget.id)" type="button"
										class="btn btn-sm  m-1 btn-outline-secondary ">
										<i class="fa-solid fa-coins mr-1"></i>Ver presupuesto
									</button>
									<button v-else @click="viewPay(buy.buy_id)" type="button"
										class="btn btn-sm  m-1 btn-outline-secondary ">
										<i class="fa-solid fa-basket-shopping mr-1"></i>Ver compra
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="8">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<ModalFormComponent />
		<ModalFormComponentPays :prop_edit="false" />
		<ModalFormComponentBudgets />
		<ModalFormComponentBuy />
		<ModalBudgetsTable />
		<ModalBuysTable />
		<ModalTableComponentPays/>

	</div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
// import pagination from 'laravel-vue-pagination';
import request from '@/services/request'
import ModalFormComponent from '@/components/checks/ModalFormComponent.vue';
import ModalFormComponentPays from '@/components/pays/ModalFormComponent.vue';
import ModalFormComponentBudgets from '@/components/budgets/ModalFormComponent.vue'
import ModalFormComponentBuy from '@/components/buy/ModalFormComponent.vue'
import ModalBudgetsTable from '@/components/budgets/ModalTableComponent.vue'
import ModalBuysTable from '@/components/buy/ModalTableComponent.vue'
import ModalTableComponentPays from '@/components/pays/ModalTableComponent.vue';

export default {
	name: "companies-table",
	components: {
		ModalFormComponent,
		ModalFormComponentPays,
		ModalFormComponentBudgets,
		ModalFormComponentBuy,
		ModalBudgetsTable,
		ModalBuysTable,
		ModalTableComponentPays
	},
	data() {
		return {
			checks: {
				data:[]
			},
			search: {
				name:'',
				authorized:1
			},
		}
	},
	mounted() {
		this.list();
		this.setHeaderTitle('Cheque');
		let value = localStorage.getItem('bill_data');
		if (value) {
			this.formData();
		}
	},
	created() {
		Event.$on("event-load-checks", () => {
			this.list();
		});
	},
	methods: {
		formData(edit = {}) {
			console.log(edit);
			Event.$emit('event-show-addChecks', edit);
		},
		formListPays(check) {
			Event.$emit('event-show-listPays', check);
		},
		formDataPays(pay) {
			Event.$emit('event-show-addPays', pay.check_id, pay);
		},
		showBudget(id) {
			Event.$emit('event-load-budget', id);
		},
		viewPay(id) {
			Event.$emit('event-load-buy', id);
		},
		deleted: function (id) {
			let tit = 'Eliminar Cheque';
			let txt = 'Seguro que quiere eliminar el Cheque';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		approve: function (id) {
			let tit = 'Aprovar Cheque';
			let txt = 'Seguro que quiere aprovar el Cheque';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let _endpoint = '/checks_approve';
					let form = {
						id: id
					};
					try {
						let response = await request.post(_endpoint, form).then(function (response) { return response; });
						this.list();
						this.successNotification(response.data.message);
					} catch (error) {
						this.errors = await this.errorNotification(error)
					}
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/checks/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		async list(page = 1) {
			let service = request;
			let search = this.search;
			let _endpoint = '/checks';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.checks = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	}, 
	watch:{
		"search.authorized": function(){
			this.list();
		}
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #214d83;
    border-color: #dee2e6 #dee2e6 #fff;
}
</style>
