<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                name: '',
                amount: '',
                biill_type_id: '',
                company_id: '',
                description: '',
                file: '',
                xml: '',
                user_id: '',
            },
            file: '',
            fileName: '',
            xml: '',
            xmlName: ''
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.name = '';
            this.form.description = '';
            this.form.file = '';
            this.form.amount = '';
            this.form.xml = '';
            if (this.authUser.organization_charts_id == 2) {
                this.form.user_id = this.authUser.id;
            } else {
                this.form.user_id = '';
            }
            this.form.biill_type_id = '';
            this.form.company_id = '';
            this.fileName = '';
            this.xmlName = '';
            this.file = '';
            this.xml = '';
            this.$modal.hide('addBill');
        },
        catch_file(event) {
            if (event.target.files[0].type == "application/pdf") {
                this.form.file = event.target.files[0];
                this.fileName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },
        catch_xml(event) {
            console.log(event.target.files[0].type);
            if (event.target.files[0].type == "text/xml") {
                this.form.xml = event.target.files[0];
                this.xmlName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos XML',
                });
            }
        },
        async saveBuildings() {
            const data_form = new FormData();
            data_form.append('id', this.form.id);
            data_form.append('name', this.form.name);
            data_form.append('description', this.form.description);
            data_form.append('file', this.form.file);
            data_form.append('amount', this.form.amount);
            data_form.append('xml', this.form.xml);
            data_form.append('user_id', this.form.user_id);
            data_form.append('company_id', this.form.company_id);
            data_form.append('biill_type_id', this.form.biill_type_id);
            let _endpoint = '/bills';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/bills/${this.form.id}`;
            }
            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-bills');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    watch: {
        'form.user_id'() {
            this.company_id = '';
            this.catalogue.get_companies_users(this.form.user_id);
        }
    },
    mounted() {
        this.catalogue.get_users_profile(2);
        this.catalogue.get_bills_types();
        if (this.authUser.organization_charts_id == 2) {
            this.form.user_id = this.authUser.id;
            this.catalogue.get_companies_users(this.authUser.id);
        }
    },
    created() {
        Event.$on("event-show-addBill", (account) => {
            console.log(account);
            if (account.id) {
                this.form.id = account.id;
                this.form.name = account.name;
                this.form.description = account.description;
                this.form.user_id = account.user_id;
                this.form.amount = account.amount;
                this.form.biill_type_id = account.biill_type_id;
                this.form.company_id = account.company_id;
                if (account.file) {
                    this.file = account.file;
                }
                if (account.xml) {
                    this.xml = account.xml;
                }
            }
            this.$modal.show('addBill');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>
<template>
    <modal name="addBill" :width="'80%'" :maxHeight="300" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información Factura</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 mt-3" v-if="authUser.organization_charts_id != 2">
                        <label>Proveedor</label>
                        <select type="text" class="form-control" v-model="form.user_id">
                            <option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">{{
                                user.name }} {{ user.last_name }}</option>
                        </select>
                        <div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <label>Concepto</label>
                        <input type="text" class="form-control" v-model="form.name">
                        <div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Monto</label>
                        <money class="form-control" v-model="form.amount"></money>
                        <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Tipo</label>
                        <select type="text" class="form-control" v-model="form.biill_type_id">
                            <option v-for="(type, index) in catalogue.bills_types" :value="type.id" :key="index">{{
                                type.name }}</option>
                        </select>
                        <div v-if="errors && errors.biill_type_id" class="text-danger">{{ errors.biill_type_id[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Empresa</label>
                        {{ }}
                        <select type="text" class="form-control" v-model="form.company_id">
                            <option v-for="(company, index) in catalogue.companies_users" :value="company.id" :key="index">
                                {{
                                    company.name }}</option>
                        </select>
                        <span class="text-info text-sm"
                            v-if="catalogue.companies_users.length == 0 && form.user_id != ''">No tienes empresas
                            relacionadas</span>
                        <div v-if="errors && errors.company_id" class="text-danger">{{ errors.company_id[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Descripción</label>
                        <textarea type="text" class="form-control" v-model="form.description"></textarea>
                        <div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>PDF</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" class="custom-file-input" :class="file != '' ? 'pr-5' : ''" id="customFile"
                                ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>
                    <div class="col-md-6 mt-3">
                        <label>XML</label><br>
                        <div class="custom-file" :style="xml != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" class="custom-file-input" :class="xml != '' ? 'pr-5' : ''" id="customFile"
                                ref="file" @change="catch_xml">
                            <label class="custom-file-label text-left" for="customFile">{{ xmlName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${xml}`" v-if="xml != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.xml" class="text-danger">{{ errors.xml[0] }}</div>

                    </div>

                    <div class="col-md-12">
                        <hr>
                        <button type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>