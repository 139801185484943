import request from './request'

export default class Catalogos {

    constructor() {
        this.all_users = [];
        this.services = [];
        this.type_documents = [];
        this.payment_periods = [];
        this.banks = [];
        this.type_account = [];
        this.users_profile = [];
        this.buildings = [];
        this.concepts = [];
        this.payments_recurrings = [];
        this.business_type = [];
        this.provider_types = [];
        this.type_payments = [];
        this.budgets_user = [];
        this.buys_user = [];
        this.bills_user = [];
        this.departamens_buildings = [];
        this.folio_budgets = '';
        this.bills_types = [];
        this.companies_users = [];
    }

    async get_payment_periods() {
        let _endpoint = '/catalogue/payment_period';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.payment_periods = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_services() {
        let _endpoint = '/catalogue/services';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.services = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_type_documents() {
        let _endpoint = '/catalogue/type_documents';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.type_documents = data;
            }
        } catch (error) {
            console.log(error);
        }
    }



    async get_all_users(query = '') {
        let search = { _search: query };
        let _endpoint = '/catalogue/users';
        try {
            let { data } = await request.get(_endpoint, search)
            if (data) {
                this.all_users = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_banks() {
        let _endpoint = '/catalogue/banks';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.banks = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_type_account() {
        let _endpoint = '/catalogue/type_account';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.type_account = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_users_profile(id) {
        let _endpoint = '/catalogue/users_profile/' + id;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.users_profile = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_buildings() {
        let _endpoint = '/catalogue/buildings';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.buildings = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_concepts() {
        let _endpoint = '/concept';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.concepts = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_payments_recurrings() {
        let _endpoint = '/catalogue/payments_recurrings';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.payments_recurrings = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_folio_budgets(id) {
        let _endpoint = '/catalogue/folio_budgets/' + id;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.folio_budgets = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_business_type() {
        let _endpoint = '/business_type';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.business_type = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_provider_types() {
        let _endpoint = '/catalogue/provider_types';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.provider_types = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_type_payments() {
        let _endpoint = '/catalogue/type_payments';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.type_payments = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_budgets_user(user = '', concept_id = '') {
        let _endpoint = '/catalogue/budgets_user?user_id='+user+'&concept_id='+concept_id;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.budgets_user = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_buys_user(user = '', concept_id = '') {
        let _endpoint = '/catalogue/buys_user?user_id='+user+'&concept_id='+concept_id;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.buys_user = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_bills_user(user = '', id = '') {
        let _endpoint = '/catalogue/bills_user?id='+id+'&user_id='+user;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.bills_user = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_departamens_buildings(building = '') {
        let _endpoint = '/departments/list?building_id='+building;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.departamens_buildings = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_bills_types() {
        let _endpoint = '/catalogue/bills_types';
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.bills_types = data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async get_companies_users(user_id = '') {
        let _endpoint = '/catalogue/companies_users/'+user_id;
        try {
            let { data } = await request.get(_endpoint)
            if (data) {
                this.companies_users = data.companies;
            }
        } catch (error) {
            console.log(error);
        }
    }

}