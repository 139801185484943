<template>
    <modal name="addIncomes" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información Ingreso</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label> Propiedad </label>
                            <select type="text" class="form-control" v-model="form.building_id">
                                <option v-for="(building, index) in catalogue.buildings" :value="building.id" :key="index">
                                    {{ building.name }}</option>
                            </select>
                            <div v-if="errors && errors.building_id" class="text-danger">{{ errors.building_id[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label> Departamento </label>
                            <select v-model="form.indiviso_id" class="form-control">
                                <option value="">Sin departamento</option>
                                <option v-for="(departament, index) in catalogue.departamens_buildings" :key="index"
                                    :value="departament.id"> Número {{ departament.number }} / Piso {{ departament.level }}
                                </option>
                            </select>
                            <div v-if="errors && errors.indiviso_id" class="text-danger">{{ errors.indiviso_id[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label> Monto </label>
                            <money class="form-control" v-model="form.amount"></money>
                            <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label> Concepto </label>
                            <select type="text" class="form-control" v-model="form.concept_id">
                                <option v-for="(concept, index) in catalogue.concepts" :value="concept.id" :key="index">
                                    {{ concept.name }}</option>
                            </select>
                            <div v-if="errors && errors.concept_id" class="text-danger">{{ errors.concept_id[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Recurencia </label>
                            <select type="text" class="form-control" v-model="form.payment_period_id">
                                <option v-for="(payment_period, index) in catalogue.payment_periods"
                                    :value="payment_period.id" :key="index">
                                    {{ payment_period.name }}</option>
                            </select>
                            <div v-if="errors && errors.payment_period_id" class="text-danger">{{
                                errors.payment_period_id[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Tipo </label>
                            <select type="text" class="form-control" v-model="form.payments_recurring_id">
                                <option v-for="(payments_recurring, index) in catalogue.payments_recurrings"
                                    :value="payments_recurring.id" :key="index">
                                    {{ payments_recurring.name }}</option>
                            </select>
                            <div v-if="errors && errors.payments_recurring_id" class="text-danger">{{
                                errors.payments_recurring_id[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Fecha</label>
                            <date-picker v-model="form.date" valueType="format"></date-picker>
                            <div v-if="errors && errors.date" class="text-danger">{{ errors.date[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label> Descripción </label>
                            <textarea type="text" v-model="form.description" class="form-control"></textarea>
                            <div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 ">
                        <label>Archivo</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" class="custom-file-input" :class="file != '' ? 'pr-5' : ''" id="customFile"
                                ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>

                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="col-md-12">
                        <button type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
import request from '@/services/request'
import Catalogue from '@/services/catalogue';
import { mapActions } from 'vuex';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
    components: {
        DatePicker
    },
    name: "Incomes-form",
    data() {
        return {
            form: {
                id: '',
                building_id: '',
                indiviso_id: '',
                amount: '',
                concept_id: '',
                payment_period_id: '',
                payments_recurring_id: '',
                date: '',
                description: '',
                file: ''
            },
            bank_accounts: [],
            errors: {},
            // 
            catalogue: new Catalogue(),
            file: '',
            fileName: ''
        }
    },
    mounted() {
        this.catalogue.get_buildings();
        this.catalogue.get_concepts();
        this.catalogue.get_payment_periods();
        this.catalogue.get_payments_recurrings();
        this.catalogue.get_users_profile(2);
        this.catchParameters();
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.building_id = '';
            this.form.indiviso_id = '';
            this.form.amount = '';
            this.form.concept_id = '';
            this.form.payment_period_id = '';
            this.form.payments_recurring_id = '';
            this.form.date = '';
            this.form.description = '';
            this.form.file = '';
            this.file = '';
            this.fileName = '';
            this.$modal.hide('addIncomes');
        },
        async catchParameters() {
            let params = this.$route.query;
            if (params.bill) {
                this.form.bill = params.bill;
            }
            if (params.supplier) {
                this.form.user_id = params.supplier;
            }
        },
        catch_file(event) {
            if (event.target.files[0].type == "application/pdf") {
                this.form.file = event.target.files[0];
                this.fileName = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },
        async saveBuildings() {
            const data_form = new FormData();
            data_form.append('id', this.form.id);
            data_form.append('building_id', this.form.building_id);
            data_form.append('indiviso_id', this.form.indiviso_id);
            data_form.append('amount', this.form.amount);
            data_form.append('concept_id', this.form.concept_id);
            data_form.append('payment_period_id', this.form.payment_period_id);
            data_form.append('payments_recurring_id', this.form.payments_recurring_id);
            data_form.append('date', this.form.date);
            data_form.append('description', this.form.description);
            data_form.append('file', this.form.file);

            let _endpoint = '/incomes';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/incomes/${this.form.id}`;
            }

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-incomes');
                }
            } catch (error) {
                this.errors = await this.errorNotification(error);
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    created() {
        Event.$on("event-form-income", (info) => {
            if (info) {
                this.form.id = info.id;
                this.form.building_id = info.building_id;
                this.form.indiviso_id = info.indiviso_id;
                this.form.amount = info.amount;
                this.form.concept_id = info.concept_id;
                this.form.payment_period_id = info.payment_period_id;
                this.form.payments_recurring_id = info.payments_recurring_id;
                this.form.date = info.date;
                this.form.description = info.description;
                if(info.file){
                    this.file = info.file;
                }
            }
            console.log('poso2');

            this.$modal.show('addIncomes');
        });
    },
    watch: {
        'form.building_id'(value) {
            this.catalogue.get_departamens_buildings(value);
        },
    }
}
</script>

<style scoped>
.pagination {
    margin-bottom: 0;
}

.mx-datepicker {
    width: 100%;
}

.fs-6 {
    font-size: 12px;
}

.custom-file-label::after {
    content: "Archivo";
}
</style>
