<script>
import { mapGetters } from 'vuex';
export default {
	data() {
		return {
		}
	},
	methods: {
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
}

</script>
<template>
	<nav class="mt-2">
		<ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
			<!-- Add icons to the links using the .nav-icon class with font-awesome or any other icon font library -->
			<li class="nav-item">
				<router-link :to="{ name: 'app-home' }" class="nav-link">
					<i class="nav-icon fas fa-th"></i>
					<p>
						Panel principal
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'users'">
				<router-link to="/users" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-user"></i>
					<p>
						Usuarios
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'buildings'">
				<router-link to="/buildings" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-map-marker-alt"></i>
					<p>
						Propiedades
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'rules'">
				<router-link to="/rules" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-pen-ruler"></i>
					<p>
						Reglas
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'concepts'">
				<router-link to="/concepts" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-clipboard-list"></i>
					<p>
						Conceptos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'companies'">
				<router-link to="/companies" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-id-card-clip"></i>
					<p>
						Empresas
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'bank_accounts'">
				<router-link to="/bank_accounts" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-money-check-dollar"></i>
					<p>
						Cuentas de Bancos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'permissions'">
				<router-link to="/permissions" active-class="active" class="nav-link">
					<i class="nav-icon fas fa-lock"></i>
					<p>
						Permisos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'bill'">
				<router-link to="/bill" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-file-invoice-dollar"></i>
					<p>
						Facturas
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'budgets'">
				<router-link to="/budgets" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-coins"></i>
					<p>
						Presupuestos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'buy'">
				<router-link to="/buy" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-basket-shopping"></i>
					<p>
						Compras
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'checks'">
				<router-link to="/checks" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-receipt"></i>
					<p>
						Cheques
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'pays'">
				<router-link to="/pays" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-hand-holding-dollar"></i>
					<p>
						Pagos
					</p>
				</router-link>
			</li>
			<li class="nav-item" v-can="'incomes'">
				<router-link to="/incomes" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-money-bill-trend-up"></i>
					<p>
						Ingresos
					</p>
				</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/profile" active-class="active" class="nav-link">
					<i class="nav-icon fa-solid fa-user-gear"></i>					
					<p>
						Editar cuenta
					</p>
				</router-link>
			</li>
		</ul>
	</nav>
</template>
