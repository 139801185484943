<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Facturas</h3>
				<div class="card-tools">
					<button @click="newDepartment()" v-can="'add_bill'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> Factura
					</button>

				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th>Proveedor</th>
								<th>Nombre</th>
								<th>Monto</th>
								<th>Typo</th>
								<th>Empresa</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="bills">
							<tr v-for="(bill, index) in bills.data" :key="index">
								<td>{{ bill.id }}</td>
								<td>
									<span v-if="bill.user">{{ bill.user.name }} {{ bill.user.last_name }}</span>
									<span v-else> Sin preveedor</span>
								</td>
								<td>{{ bill.name }}</td>
								<td>{{ bill.amount | toCurrency }}</td>
								<td>{{ bill.type.name }}</td>
								<td>
									<span v-if="bill.company">{{ bill.company.name }}</span>
									<span v-else> Sin empresa</span>
								</td>
								<td>
									<a v-tooltip.bottom-start="'Tooltip on bottom'" v-if="bill.file" target="_blank"
										class="btn m-1 btn-outline-secondary btn-sm"
										:href="`${globalUrlFiles}${bill.file}`">
										<i class="fas fa-file-pdf "> </i> Archivo PDF
									</a>
									<a v-tooltip.bottom-start="'Tooltip on bottom'" v-if="bill.xml" target="_blank"
										class="btn m-1 btn-outline-secondary btn-sm" :href="`${globalUrlFiles}${bill.xml}`">
										<i class="fas fa-file "> </i> Archivo XML
									</a>
									<button @click="newDepartment(bill)" v-tooltip.bottom-start="'Tooltip on bottom'"
										v-can="'edit_bill'" class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</button>
									<button type="button" @click="deleted(bill.id)"
										v-tooltip.bottom-start="'Tooltip on bottom'" v-can="'deleted_bill'"
										class="btn m-1 btn-outline-danger btn btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>

									<button type="button" v-tooltip.bottom-start="'Tooltip on bottom'" v-if="!bill.check"
										@click="convertBill(bill)" v-can="'approve_bill'"
										class="btn m-1 btn-outline-info btn btn-sm ">
										<i class="fa-solid fa-money-bills"></i> Generar orden de pago
									</button>

									<button type="button" v-tooltip.bottom-start="'Tooltip on bottom'" v-if="bill.check"
										@click="showCheck(bill.check)" class="btn m-1 btn-outline-success btn btn-sm ">
										<i class="fa-solid fa-hand-holding-dollar"></i> Información del cheque
									</button>

									<div class="d-inline" v-if="bill.check && bill.biill_type_id == 2">
										<button type="button" v-if="bill.check.pay"
											v-tooltip.bottom-start="'Tooltip on bottom'" @click="newPay(bill.check.pay)"
											class="btn m-1 btn-outline-warning btn btn-sm ">
											<i class="fa-solid fa-comments-dollar"></i> Complemento de pago 
										</button>
									</div>


								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<ModalFormComponent />
		<ModalFormComponentCheck :prop_edit="false" />
		<ModalFormReceiptComponent />
		<modal name="addTipe" :width="'80%'" :maxHeight="300" :height="'auto'">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Tipo de gasto</h5>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-md-6">
						<button @click="redireCreate(1)" class="btn btn-lg p-4 btn-block btn-info text-center">
							<i class="fa-solid fa-coins fa-4x"></i>
							<H3>Presupuesto</H3>
						</button>

					</div>
					<div class="col-md-6">
						<button @click="redireCreate(2)" class="btn btn-lg p-4 btn-block btn-info text-center">
							<i class="fa-solid fa-basket-shopping fa-4x"></i>
							<H3>Presupuesto</H3>
						</button>
					</div>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import request from '@/services/request'
import ModalFormComponent from '../../components/bill/ModalFormComponent.vue';
import ModalFormComponentCheck from '@/components/checks/ModalFormComponent.vue';
import ModalFormReceiptComponent from '@/components/pays/ModalFormReceiptComponent.vue';

export default {
	name: "bills-table",
	components: {
		ModalFormComponent,
		ModalFormComponentCheck,
		ModalFormReceiptComponent
	},
	data() {
		return {
			bills: {},
			search: '',
			idConvert: '',
			idUser: '',
		}
	},
	mounted() {
		this.list();
		this.setHeaderTitle('Facturas');
	},
	created() {
		Event.$on("event-load-bills", () => {
			this.list();
		});
	},
	methods: {
		showCheck(edit = {}) {
			Event.$emit('event-show-addChecks', edit);
		},
		redireCreate(type) {
			let url = 'add_buy';
			if (type == 1) {
				url = 'add_budgets';
			}
			let parms = { bill: this.idConvert, supplier: this.idUser };
			console.log(parms);
			this.$router.push({ name: url, query: parms });
		},
		convertBill(bill) {
			let date = this.moment(bill.created_at).format("YYYY-MM-DD");
			let billD = {
				'idConvert': bill.id,
				'idUser': bill.user_id,
				'date': date,
				'amount': bill.amount,
			}
			localStorage.setItem('bill_data', JSON.stringify(billD));
			this.$router.push('/checks');
		},
		newDepartment(account = {}) {
			Event.$emit('event-show-addBill', account);
		},
		newPay(account = {}) {
			Event.$emit('event-show-addPays', account);
		},
		deleted: function (id) {
			let tit = 'Eliminar factura';
			let txt = 'Seguro que quiere eliminar la factura';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/bills/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		async list(page = 1) {
			let service = request;
			let search = this.search;
			let _endpoint = '/bills';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.bills = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
