<template>
    <div class="app-main__outer">
        <div class="app-main__inner">
            
            <div class="row">
                <div class="col-md-5">
                    <div class="main-card mb-3 card  card-outline card-info">
                        <div class="card-body">
                            <form @submit.prevent="triggerSave" method="post">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="name" class="control-label">Descripción:</label>
                                            <textarea required name="name" type="text" class="form-control"
                                                v-model="rule.name"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="offset-md-6 col-md-6 text-right">
                                        <button v-if="rule.id" type="button" class="btn m-2 btn-danger"
                                            @click="cancelEdit()">Cancelar</button>
                                        <button type="submit" class="btn m-2 btn-primary">Guardar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="main-card mb-3 card  card-outline card-info">
                        <div class="card-body">
                            <table class="mb-0 table table-striped">
                                <thead class="bg-navy text-white">
                                    <tr>
                                        <th>Folio</th>
                                        <th>Nombre</th>
                                        <th><i class="fa fa-cogs"></i></th>
                                    </tr>
                                </thead>
                                <tbody v-if="rules.length > 0">
                                    <tr v-for="(rule, index) in rules" :key="index">
                                        <th scope="row">#{{ rule.folio }}</th>
                                        <td>{{ rule.name }}</td>
                                        <td><button @click="editRule(rule)"
                                                class="btn btn-square bg-info text-white"><i
                                                    class="fa fa-edit"></i></button></td>
                                    </tr>

                                    <tr v-if="rules.length == 0">
                                        <td colspan="3">
                                            <h6 class="text-center">No hay reglas actualmente</h6>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex';
// import pagination from 'laravel-vue-pagination';
import request from '@/services/request'
export default {
    name: "rules-table",
    data() {
        return {
            rule: {
                id: '',
                name: ''
            },
            rules:[]
        }
    },
    methods: {
        async triggerSave() {

            let form = this.rule;

            let _endpoint = '/rules';
            if (form.id != "") {
                form._method = 'PUT';
                _endpoint = `/rules/${form.id}`;
            }

            try {
                let { data } = await request.postMedia(_endpoint, form)
                if (data) {
                    this.cancelEdit();
                    this.list();
                    this.successNotification(data.message);
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    await this.errorNotification(error);                   
                }
            }
        },
        async list() {
            let service = request;
            let _endpoint = '/rules';
            try {
                let { data } = await service.get(_endpoint)
                if (data) {
                    this.rules = data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        editRule(rule) {
            this.rule = Object.assign({}, rule);
        },
        cancelEdit() {
            this.rule.id = '';
            this.rule.name = '';
        },
        ...mapMutations({
            setHeaderTitle: 'setHeaderTitle',
        }),
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    mounted() {
        this.list();
        this.setHeaderTitle('Reglas');
    },
}
</script>
