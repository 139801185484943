<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Pagos</h3>
				<div class="card-tools">
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th>Tipo</th>
								<th>Proveedor</th>
								<th>Concepto</th>
								<th>Monto</th>
								<th>Fecha</th>
								<th>Tipo de saldo</th>
								<th>Aprobado por</th>
								<th>Fecha de aprobación</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="pays">
							<tr :class="buy.authorization_user_id ? 'bg-info' : ''" v-for="(buy, index) in pays.data"
								:key="index">
								<td>{{ buy.id }}</td>
								<td>
									<span v-if="buy.type_id == 1"> Presupuesto </span>
									<span v-else> Compra </span>
								</td>
								<td>
									<span v-if="buy.provider"> {{ buy.provider.name }} {{ buy.provider.last_name }}</span>
									<span v-else>Sin proveedor</span>
								</td>
								<td>
									<span v-if="buy.concept"> {{ buy.concept.name }}</span>
									<span v-else>Sin Concepto</span>
								</td>
								<td>
									{{ buy.date }}
								</td>
								<td>
									{{ buy.amount | toCurrency }}
								</td>
								<td>
									<span v-if="buy.type_payment"> {{ buy.type_payment.name }}</span>
									<span v-else>Sin Tipo de Saldo</span>
								</td>
								<td>
									<span v-if="buy.user_approved"> {{ buy.user_approved.name }} {{
										buy.user_approved.last_name }}</span>
									<span v-else>Sin Usuario</span>
								</td>
								<td>
									<span v-if="buy.user_approved"> {{ buy.authorization_date | toDateTime }} </span>
									<span v-else>Sin Fecha</span>
								</td>
								<!-- <td>
									<span v-if="buy.pay"> {{ buy.pay.user_approved.name }} {{
										buy.pay.user_approved.last_name }}</span>
									<span v-else>Sin Usuario</span>
								</td>
								<td>
									<span v-if="buy.pay"> {{ buy.pay.authorization_date | toDateTime }} </span>
									<span v-else>Sin Fecha</span>
								</td> -->
								<td>
									<button v-if="!buy.pay" @click="cancelPays(buy.id)" v-can="'edit_checks'"
										class="btn btn-outline-secondary m-1 btn-sm">
										<i class="fa-solid fa-xmark"></i> Cancelar aprobación
									</button>

									<button @click="formData(buy)" v-can="'edit_checks'"
										class="btn btn-outline-primary m-1 btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</button>


									<button type="button" v-if="!buy.pay" @click="deleted(buy.id)" v-can="'deleted_checks'"
										class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>
									<!-- <button @click="formDataPays(buy, buy.pay)" v-can="'add_pays'" class="btn m-1 btn-sm btn-outline-warning">
										<i class="fa-solid fa-hand-holding-dollar mr-1"> </i>
										<span>Ver pago</span>
									</button> -->
									<button @click="formListPays(buy)" v-can="'add_pays'" class="btn m-1 btn-sm btn-outline-warning">
										<i class="fa-solid fa-hand-holding-dollar mr-1"> </i>
										<span>Ver pago</span>
									</button>
									<button @click="formDataPaysFast(buy)" v-can="'add_pays_fast'"
										class="btn btn-outline-info m-1 btn-sm" v-if="buy.pay.length == 0">
										<i class="fa-regular fa-handshake mr-1"></i>
										<span>Pago rapido</span>
									</button>
								</td> 
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<ModalFormComponent />
		<ModalTableComponentPays :prop_edit="false" />
	</div>
</template>



<script>
import ModalFormComponent from '@/components/checks/ModalFormComponent.vue';
import ModalTableComponentPays from '@/components/pays/ModalTableComponent.vue';

import { mapMutations, mapActions } from 'vuex';
// import pagination from 'laravel-vue-pagination';
import request from '@/services/request'
export default {
	name: "companies-table",
	components: {
		ModalFormComponent,
		ModalTableComponentPays
	},
	data() {
		return {
			pays: {},
			search: '',
		}
	},
	mounted() {
		this.list();
		this.setHeaderTitle('Compras');
	},
	created() {
		Event.$on("event-load-checks", () => {
			this.list();
		});
	},
	methods: {
		cancelPays(id) {
			let vm = this;
			console.log(id);
			this.$swal({
				title: 'Motivos de Cancelación',
				input: 'text',
				inputAttributes: {
					autocapitalize: 'off'
				},
				showCancelButton: true,
				confirmButtonText: 'Guardar',
				showLoaderOnConfirm: true,
				preConfirm: async (message) => {
					console.log(message);
					let form = {
						id: id,
						message: message,
					};
					let _endpoint = '/cancel_pays';
					try {
						let { data } = await request.postMedia(_endpoint, form)
						if (data) {
							console.log(data);
							await vm.successNotification(data.message);
							await vm.list();
						}
					} catch (error) {
						vm.errors = {};
						if (error.response) {
							vm.errors = vm.errorNotification(error);
						}
					}
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					console.log(result);
				}
			})

		},
		formData(edit = {}) {
			console.log(edit);
			Event.$emit('event-show-addChecks', edit);
		},
		formDataPays(check, edit = {}) {
			Event.$emit('event-show-addPays', check, edit);
		},
		formListPays(check) {
			Event.$emit('event-show-listPays', check);
		},
		formDataPaysFast(check) {
			this.$swal({
				title: 'Pago rapido',
				text: 'Se aprobara el pago rapido',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Pagar',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let form = {
						check_id: check.id,
						date: this.moment().format("YYYY-MM-DD"),
						amount: check.amount,
						description: 'Pago aprobado'
					};
					let _endpoint = '/pays';
					try {
						let { data } = await request.postMedia(_endpoint, form)
						if (data) {
							console.log(data);
							this.successNotification(data.message);
							this.list();
						}
					} catch (error) {
						this.errors = {};
						if (error.response) {
							this.errors = await this.errorNotification(error);
						}
					}
				}
			});
		},
		deleted: function (id) {
			let tit = 'Eliminar cheque';
			let txt = 'Seguro que quiere eliminar la cheque';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		approve: function (id) {
			let tit = 'Aprovar Presupuestos';
			let txt = 'Seguro que quiere aprovar el presupuestos';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let _endpoint = '/pays_approve';
					let form = {
						id: id
					};
					try {
						let response = await request.post(_endpoint, form).then(function (response) { return response; });
						this.list();
						this.successNotification(response.data.message);
					} catch (error) {
						this.errors = await this.errorNotification(error)
					}
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/pays/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		async list(page = 1) {
			let service = request;
			let search = this.search;
			let _endpoint = '/pays';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.pays = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
