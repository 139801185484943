<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                building_id: '',
                number: '',
                level: '',
                file: '',
                description: '',
                _method: 'POST',
            },
            file: '',
            fileName: ''
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.number = '';
            this.form.level = '';
            this.form.file = '';
            this.form.description = '';
            this.file = '';
            this.fileName = '';
            this.$modal.hide('addDepartament');
        },
        catch_file(event) {
            this.form.file = event.target.files[0];
            this.fileName = event.target.files[0].name;
        },
        async saveBuildings() {
            try {
                let form = this.form;
                const data_form = new FormData();
                data_form.append('id', this.form.id);
                data_form.append('building_id', this.form.building_id);
                data_form.append('number', this.form.number);
                data_form.append('level', this.form.level);
                data_form.append('file', this.form.file);
                data_form.append('description', this.form.description);

                let _endpoint = '/departments/store';
                if (form.id != "") {
                    _endpoint = `/departments/store/${form.id}`;
                }

                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-departaments');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    mounted() {
        let id = this.$route.params.id;
        if (id) {
            this.form.building_id = id;
        }
        this.catalogue.get_users_profile(9);
    },
    created() {
        Event.$on("event-show-addDepartament", (info) => {
            if (info) {
                this.form.id = info.id;
                this.form.building_id = info.building_id;
                this.form.number = info.number;
                this.form.level = info.level;
                this.form.description = info.description;

                if (info.file) {
                    this.file = info.file;
                }

            }
            this.$modal.show('addDepartament');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    }
}
</script>
<template>
    <modal v-bind:click-to-close="false" name="addDepartament" :width="'80%'" :scrollable="true" :maxHeight="300"
        :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información Pago</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <label>Número</label><br>
                        <input class="form-control" v-model="form.number">
                        <div v-if="errors && errors.number" class="text-danger">{{ errors.number[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Piso</label>
                        <input class="form-control" v-model="form.level">
                        <div v-if="errors && errors.level" class="text-danger">{{ errors.level[0] }}</div>
                    </div>

                    <div class="col-md-12 mt-3">
                        <label>Descripción</label>
                        <textarea type="text" :disabled="!prop_edit" class="form-control"
                            v-model="form.description"></textarea>
                        <div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Archivo</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" class="custom-file-input" :class="file != '' ? 'pr-5' : ''" id="customFile"
                                ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>


                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>