<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Presupuestos</h3>
				<div class="card-tools">
					<router-link :to="`/budgets/form`" v-can="'add_budgets'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> Presupuesto
					</router-link>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th>Propiedad</th>
								<th>Departamento</th>
								<th>Proveedor</th>
								<th>Monto</th>
								<th>Pagado</th>
								<th>Resta</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="budgets">
							<tr :class="buy.authorization_user_id ? 'bg-info' : ''" v-for="(buy, index) in budgets.data"
								:key="index">
								<td>{{ buy.id }}</td>
								<td>
									<span v-if="buy.building"> {{ buy.building.name }}</span>
									<span v-else>Sin propiedad</span>
								</td>
								<td>
									<span v-if="buy.departament"> Número {{ buy.departament.number }} / Piso {{
										buy.departament.level }}</span>
									<span v-else>Sin propiedad</span>
								</td>
								<td>
									<span v-if="buy.user"> {{ buy.user.name }} {{ buy.user.last_name }}</span>
									<span v-else>Sin proveedor</span>
								</td>
								<td>
									{{ buy.amount | toCurrency }}
								</td>
								<td>
									{{ paysCheks(buy.checks) | toCurrency }}
								</td>
								<td>
									{{ buy.amount - paysCheks(buy.checks) | toCurrency }}
								</td>
								<td>
									<router-link :to="`/budgets/form/${buy.id}`" v-can="'edit_budgets'"
										class="btn btn-outline-primary m-1 btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</router-link>
									<button type="button" @click="deleted(buy.id)" v-can="'deleted_budgets'"
										class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>
									<button type="button" @click="approve(buy.id)" v-if="!buy.authorization_user_id"
										v-can="'approve_budgets'" class="btn-outline-warning btn m-1 btn-sm ">
										<i class="fa fa-check"></i> Aprobar
									</button>
									<button type="button" v-if="buy.checks.length > 0" @click="showCheck(buy.checks)"
										class="btn m-1 btn-outline-secondary btn btn-sm ">
										<i class="fa-solid fa-hand-holding-dollar"></i> Pagos
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<ModalTableComponent />

	</div>
</template>

<script>
import ModalTableComponent from '@/components/checks/ModalTableComponent.vue';

import { mapMutations, mapActions } from 'vuex';
// import pagination from 'laravel-vue-pagination';
import request from '@/services/request'
export default {
	name: "companies-table",
	components: {
		ModalTableComponent,
	},
	data() {
		return {
			budgets: {},
			search: '',
		}
	},
	mounted() {
		this.list();
		this.setHeaderTitle('Presupuestos');
	},
	created() {
		Event.$on("event-load-companies", () => {
			this.list();
		});
	},
	methods: {
		showCheck(checks) {
			Event.$emit('event-show-listChecks', checks);
		},
		paysCheks(checks) {
			let sum = 0;
			console.log(checks);
			checks.forEach((s) => {
				console.log(s);
				if (s.amount) {
					sum += parseFloat(s.amount);
				}
			});
			return sum;
		},
		deleted: function (id) {
			let tit = 'Eliminar Presupuestos';
			let txt = 'Seguro que quiere eliminar el presupuestos';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		approve: function (id) {
			let tit = 'Aprovar Presupuestos';
			let txt = 'Seguro que quiere aprovar el presupuestos';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let _endpoint = '/budgets_approve';
					let form = {
						id: id
					};
					try {
						let response = await request.post(_endpoint, form).then(function (response) { return response; });
						this.list();
						this.successNotification(response.data.message);
					} catch (error) {
						this.errors = await this.errorNotification(error)
					}
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/budgets/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		async list(page = 1) {
			let service = request;
			let search = this.search;
			let _endpoint = '/budgets';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.budgets = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.bg-success {
	background-color: #28a745 !important;
}
</style>
