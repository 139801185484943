import Vue from 'vue'
import Vuex from 'vuex'
import status from './status'
import auth from './auth'
import { alert } from './alert';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    status,
    auth,
    alert,
  },
})
