import Vue from 'vue';

// Verificamos si esta logueado
const loggedIn =  JSON.parse(localStorage.getItem('user'));
let permissions = [];
if(loggedIn){
	permissions = loggedIn.profile ? loggedIn.profile.permissions.map(x => x['permission']) : [];
}

Vue.directive('can', {
	bind: function (el, binding, vnode) {
		if (!permissions
			.includes(binding.expression
				.replace(/'/g, "")
				.replace(/"/g, ""))) {
			vnode.elm.style.display = "none";
		}
	}
});
