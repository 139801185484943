<script>
import Catalogs from "@/services/catalogue";

export default {
    props: ['building_id'],
    data() {
        return {
            department:{},
            service: '',
            agreement: 'individual',
            services: [],
            catalogs: new Catalogs()
        }
    },    
    watch: {
        department() {
            if (this.department) {
                this.department.services.forEach((service) => {
                    let flag = this.services.find((s) => s.id == service.service_id);
                    if (!flag) {
                        this.services.push({
                            id: service.service_id,
                            name: service.service.name,
                            agreement: service.agreement,
                            payment_periods_id: service.payment_periods_id
                        });
                    }
                });
            }
        }
    },
    computed: {
        activeSendButton() {
            let flag = false;
            this.services.forEach((s) => {
                if (s.amount == '') {
                    flag = true;
                }
            });

            return flag;
        }
    },
    methods: {
        addService() {
            this.services.push({
                id: this.service.id,
                name: this.service.name,
                agreement: this.agreement,
                payment_periods_id: ''
            });

            this.service = '';
            this.agreement = 'individual';
        },
        closedModal() {
			this.department = '';			
			this.tab_active = 'store';
			this.$modal.hide('addServices');
		},
        deleteService(service_name) {
            this.services = this.services.filter((service_find) => service_find.name != service_name);
        },
        findService(service_name) {
            return this.services.find((service_find) => service_find.name == service_name) ? true : false;
        },
        closeModal() {
            this.services = [];
        },
        clearMoney(value) {
            if (value) {
                let re = /,/g;
                var resultado = value.replace(re, '');
                return resultado;
            } else {
                return 0;
            }
        }
    },
    created() {
        Event.$on("event-show-addServices", (department) => {
            this.catalogs.get_payment_periods();
            this.catalogs.get_services();
            this.department = department;
            this.$modal.show('addServices');
        });
    }
}
</script>
<template>
    <modal name="addServices" :width="'70%'" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <!-- <form :action="department ? `/departments/store-services/${department.id}` : '/departments/store'" method="post"
            enctype="multipart/form-data"> -->
        <form>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ingreso {{ department ? department.name : '' }} -
                    Servicios</h5>
            </div>
            <div class="modal-body">
                <input type="hidden" name="building_id" :value="(department ? department.building_id : '')">
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="" class="control-label">Servicio</label>
                            <select v-model="service" id="" class="form-control">
                                <option value="">Selecciona un servicio</option>
                                <option v-for="(serv , index) in catalogs.services" :key="index"  :value="serv">{{
                                    serv.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="" class="control-label">Tipo de convenio</label>
                        </div>

                        <div class="form-check form-check-inline">
                            <input v-model="agreement" class="form-check-input" type="radio" name="agreement"
                                id="inlineRadio1" value="individual">
                            <label class="form-check-label" for="inlineRadio1">Individual</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-model="agreement" class="form-check-input" type="radio" name="agreement"
                                id="inlineRadio2" value="conjunto">
                            <label class="form-check-label" for="inlineRadio2">En conjunto</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input v-model="agreement" class="form-check-input" type="radio" name="agreement"
                                id="inlineRadio3" value="incluido">
                            <label class="form-check-label" for="inlineRadio3">Incluido</label>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="">&nbsp;</label>
                            <button type="button" :disabled="!service" @click="addService()"
                                class="btn btn-success btn-block"> <i class="fa fa-plus"></i> Añadir</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table class="mb-0 table table-striped">
                            <thead class="bg-night-sky text-white">
                                <tr>
                                    <th>Servicio</th>
                                    <th>Convenio</th>
                                    <th>Periodo</th>
                                    <th style="width: 120px;text-align: center"><i class="fa fa-cogs"></i></th>
                                </tr>
                            </thead>
                            <tbody v-if="services.length > 0">
                                <tr  v-for="(service, index) in catalogs.services" :key="index">
                                    <th scope="row">{{ service.name | uppercase }}</th>
                                    <td>
                                        <input type="hidden" name="services[]" :value="JSON.stringify(service)">
                                        {{ service.agreement | uppercase }}
                                    </td>
                                    <td>
                                        <select class="form-control form-control-sm" v-model="service.payment_periods_id"
                                            :name="`payment_periods_id[${service.id}]`">
                                            <option value=""> Sin periodo</option>
                                            <option v-for="(payment_period, index) in catalogs.payment_periods" :key="index"
                                                :value="payment_period.id">{{ payment_period.name }}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <button type="button" @click="deleteService(service.name)" class="btn btn-danger">
                                            <i class="fa fa-trash"></i> Eliminar</button>
                                    </td>
                                </tr>

                                <tr v-if="services.length == 0">
                                    <td colspan="3">
                                        <h6 class="text-center">No hay servicios</h6>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="closedModal()" data-dismiss="modal">Cerrar</button>
                <button type="submit" class="btn btn-primary"
                    :disabled="services.length == 0 || activeSendButton">Guardar</button>
            </div>
        </form>
    </modal>
</template>
