<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    components: { DatePicker },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                check_id: '',
                date: '',
                amount: '',
                description: '',
                file: '',
            },
            file: '',
            fileName: ''
        }
    },
    methods: {
        closedModal() {
            this.form.id = ''
            this.form.check_id = '';
            this.form.date = '';
            this.form.amount = '';
            this.form.description = '';
            this.form.file = '';
            this.file = '';
            this.fileName = '';
            this.$modal.hide('addPays');
        },
        catch_file(event) {
            this.form.file = event.target.files[0];
            this.fileName = event.target.files[0].name;
        },
        async saveBuildings() {
            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('check_id', this.form.check_id)
            data_form.append('date', this.form.date)
            data_form.append('amount', this.form.amount)
            data_form.append('description', this.form.description)
            data_form.append('file', this.form.file)            
            data_form.append('_method', 'POST');

            if (this.form.date) {
                let dt = this.moment(this.form.date).format("YYYY-MM-DD");
                data_form.append('date', dt);
            }

            let _endpoint = '/pays';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/pays/${this.form.id}`;
            }

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.closedModal();
                    // Event.$emit('event-load-checks');
                    Event.$emit('event-load-pays');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-addPays", (check, account = null ) => {
            console.log(check);
            console.log(account);
            this.form.check_id = check.id;
            this.form.amount = check.amount;
            this.form.date = this.moment(check.date).toDate();
            if (account.id) {
                this.form.id = account.id;
                this.form.amount = account.amount;
                this.form.description = account.description;              
                if (account.date) {
                    let dt = this.moment(account.date).toDate();
                    this.form.date = dt;
                }
                if (account.file) {
                    this.file = account.file;
                }
            }
            this.$modal.show('addPays');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    }   
}
</script>
<template>
    <modal name="addPays" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información Pago</h5>
            </div>
            <div class="modal-body">
                <div class="row">                                   
                    <div class="col-md-6 mt-3">
                        <label>Fecha</label><br>
                        <date-picker format="YYYY-MM-DD" :disabled="!prop_edit" v-model="form.date"></date-picker>
                        <div v-if="errors && errors.date" class="text-danger">{{ errors.date[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Monto</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.amount"></money>
                        <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                    </div>                  
                    <div class="col-md-12 mt-3">
                        <label>Descripción</label>
                        <textarea type="text" :disabled="!prop_edit" class="form-control"
                            v-model="form.description"></textarea>
                        <div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Archivo</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" :disabled="!prop_edit" class="custom-file-input"
                                :class="file != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>

                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>