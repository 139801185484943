export const toggleView = (state, payload) => {
    if (payload=='list') {
        state.type_view = 'list';
        localStorage.setItem('typeView', 'list');
    } else if (payload=='grid') {
        state.type_view = 'grid';
        localStorage.setItem('typeView', 'grid');
    }
}

export const setHeaderTitle = (state, payload) => {
    if (payload && payload!='') {
        state.header_title = payload;
    } else {
        state.header_title = null;
    }
}

export const setShowTypeView = (state, payload) => {
    // console.log("setShowTypeView" , payload);
    if (payload && (payload===true || payload===false)) {
        state.show_type_view = payload;
    } else {
        state.show_type_view = false;
    }
}

export const toggleCollapse = (state, payload) => {
    state.sidebar_collapse = payload;
    localStorage.setItem('collapse', payload);
}

export const toggleLang = (state, lang) => {
    if(lang != state.lang) {
        state.lang = lang;
        localStorage.setItem('lang', lang);
    }
}

export const setUser = (state, payload) => {
    state.user = payload;
    localStorage.setItem('user', payload);
    if (state.user.language != state.lang) {
        state.lang = state.user.language;
        localStorage.setItem('lang', state.user.language);
        location.reload();
    }
}

export const setShowPages = (state, payload) => {
    state.showPages = payload;
    localStorage.setItem('showPages', payload);
}

export const setRouteChange = (state, payload) => {
    state.routeChange = payload;
}

export const showLoadingScreen = (state) => {
    state.loadingScreen = true;
}

export const hideLoadingScreen = (state) => {
    state.loadingScreen = false;
}
