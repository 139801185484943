<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Cuentas de Bancos</h3>
				<div class="card-tools">
					<button @click="newDepartment()" v-can="'add_bank_accounts'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> Cuenta
					</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th>Nombre</th>
								<th>Número</th>
								<th>Tipo</th>
								<th>Banco</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="bank_accounts">
							<tr v-for="(account, index) in bank_accounts" :key="index">
								<td>{{ account.id }}</td>
								<td>{{ account.name }}</td>
								<td>{{ account.number }}</td>
								<td>
									<span v-if="account.type_account_bank">{{ account.type_account_bank.name }}</span>
									<span v-else>Sin tipo</span>
								</td>
								<td>
									<span v-if="account.bank">{{ account.bank.name }}</span>
									<span v-else>Sin tipo</span>
								</td>
								<td>
									<a :href="`${globalUrlFiles}${account.file}`" v-if="account.file != ''" target="_blank"
										class="btn btn-sm btn-outline-info"> <i class="fa-regular fa-file-pdf"></i> Caratula
									</a>
									<button @click="newDepartment(account)" :to="`/bank_accounts/form/${account.id}`"
										v-can="'edit_bank_accounts'" class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</button>
									
									<button type="button" @click="deleted(account.id)" v-can="'deleted_bank_accounts'"
										class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<ModalFormComponent />
	</div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import request from '@/services/request'
import ModalFormComponent from '@/components/bank_accounts/ModalFormComponent.vue';
export default {
	components: {
		ModalFormComponent
	},
	name: "bank_accounts-table",
	// components: {
	// 	pagination,
	// },
	data() {
		return {
			bank_accounts: [],
			search: '',
		}
	},
	mounted() {
		this.list();
		this.setHeaderTitle('Cuentas de Bancos');
	},
	created() {
		Event.$on("event-load-bank_accounts", () => {
			this.list();
		});
	},
	methods: {
		newDepartment(account = {}) {
			Event.$emit('event-show-addContBanck', account);
		},
		deleted: function (id) {
			let tit = 'Eliminar Cuenta';
			let txt = 'Seguro que quiere eliminar al Cuenta';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/bank_accounts/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		async list(page = 1) {
			let service = request;
			let search = this.search;
			let _endpoint = '/bank_accounts';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.bank_accounts = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
