<template>
	<div class="row bg align-items-center justify-content-center m-0">
		<div class="login-box">
			<div class="card card-outline" style="background-color: #fff;">
				<div class="card-body mb-4">
					<div class="col-12 text-center mb-4">
						<img src="@/assets/img/logo.png" class="mt-3 mb-4" alt="Logo" width="150px;"><br>
						<h4 class="login-box-msg mt-n2 text-secondary">Iniciar sesión</h4>
						<p class="mt-n3">Ingresa los datos para iniciar sesión</p>
					</div>
					<form @submit.prevent="triggerLogin" class="mt-3">
						<div class="col-12" v-if="valid_mail == false">
							<div class="input-group justify-content-center">
								<input type="text" style="width: 100px;" v-model="form.email" name="email"
									class="form-control" placeholder="Correo"
									:class="{ 'is-invalid': submitted && !form.email }" />
							</div>
							<div v-if="errors && errors.email" class="text-danger mt-1">{{ errors.email[0] }}</div>
							<div class="row">
								<!-- <div class="col-6 text-left">
									<router-link to="/register" class="mt-5" style="font-size:14px;" href="">Registrarse</router-link>
								</div> -->
								<div class="col-12 text-center">
									<button class="btn btn-secondary mt-3" type="button" @click="validationMail"
										style="width: 130px;">
										Siguiente <i class="fa fa-arrow-right float-right mt-1" aria-hidden="true"></i>
									</button>
								</div>
							</div>
						</div>
						<div class="col-12" v-if="valid_mail">
							<div class="input-group" style="margin-bottom:6px !important;">
								<input type="password" v-model="form.password" name="password" class="form-control"
									placeholder="Contraseña" :class="{ 'is-invalid': submitted && !form.password }" />
							</div>
							<div v-if="errors && errors.password" class="text-danger">{{ errors.password[0] }}</div>						
							<div class="row">								
								<!-- <div class="col-12 text-center">
									<router-link to="/password-reset" class="float-right" style="font-size:13px;">
										Recuperar contraseña
									</router-link>
								</div> -->
								<div class="col-12 text-center mt-3">
									<button class="btn btn-secondary" style="width: 200px;" :disabled="submitted">
										Iniciar sesión
										<i class="float-right mt-1 fas fa-sign-in-alt"></i>
									</button>
								</div>								
							</div>
						</div>
						<div class="col-md-12 text-center">
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import request from '@/services/request';

export default {
	props: {
	},
	data() {
		return {
			form: {
				email: '',
				password: '',
			},
			valid_mail: false,
			errors: {},
			submitted: false
		}
	},
	methods: {
		async validationMail() {
			try {
				let _endpoint = 'auth/validation-user';
				let user = await request.post(_endpoint, this.form)
				if (user) {
					this.valid_mail = true;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		async triggerLogin() {
			try {
				let login = await this.loginAction(this.form)
				if (login) {
					this.$nextTick(() => {
						location.reload()
					})
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			loginAction: 'auth/login',
			errorNotification: 'alert/error',
		})
	}
};
</script>
