<script>
import ModalFormComponent from '@/components/departments/ModalFormComponent.vue';
import ModalUserDepartament from './ModalUserDepartamentComponent.vue';
import Catalogue from '@/services/catalogue';

export default {
    name:"departaments-table",
    components:{
        ModalFormComponent,
        ModalUserDepartament
    },
    data(){
        return {
            departaments:{},
            search:'',
            building_id:'',
            catalogue: new Catalogue(),
        }
    },
	methods: {
        formDepartament(info){	
			Event.$emit('event-show-addDepartament', info);
		},
        formDepartamentUser(departament, tenant){	
			Event.$emit('event-show-addDepartamentUser', departament, tenant );
		},
    },
    mounted() {
        let id = this.$route.params.id;
        if (id) {
            this.building_id = id;
            this.catalogue.get_departamens_buildings(this.building_id);
        }
    },
    created() {
        Event.$on("event-load-departaments", () => {
            this.catalogue.get_departamens_buildings(this.building_id);        
        });
    }
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <h5>Lista Departamentos</h5>
            <button @click="formDepartament()" v-can="'add_bill'" class="btn btn-sm btn-outline-success float-right" style="margin-top: -35px;">
                <i class="fa fa-plus"></i> Departamento
            </button>
            <hr>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-md-6">
                    <label>Buscar :</label>
                    <input type="text" class="form-control" v-model="search" @keyup="list()" />
                </div>
            </div>
            <br>
            <div class="table-responsive">
                <table class="table table-bordered text-center text-sm">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Número</th>
                            <th>Piso</th>
                            <th>Usuario</th>
                            <th>Renta</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="catalogue.departamens_buildings">
                        <tr v-for="(departament, index) in catalogue.departamens_buildings" :key="index">
                            <td>{{ departament.id }}</td>                          
                            <td>{{ departament.number }}</td>
                            <td>{{ departament.level }}</td>
                            <td>
                                <span v-if="departament.tenant">
                                    {{ departament.tenant.user.name }}
                                    {{ departament.tenant.user.last_name }}
                                </span>
                                <span v-else>
                                    Sin usuario
                                </span>
                            </td>  
                            <td>
                                <span v-if="departament.tenant">
                                    {{ departament.tenant.amount | toCurrency }}
                                </span>
                                <span v-else>
                                    Sin renta
                                </span>
                            </td>                                                           
                            <td>                                                           
                                <button @click="formDepartament(departament)" v-tooltip.bottom-start="'Tooltip on bottom'"
                                class="btn m-1 btn-outline-primary btn-sm">
                                    <i class="fas fa-edit "> </i> Editar
                                </button>
                                <button @click="formDepartamentUser(departament, departament.tenant)" v-tooltip.bottom-start="'Tooltip on bottom'"
                                class="btn m-1 btn-outline-secondary btn-sm">
                                    <i class="fas fa-user "> </i> Usuario
                                </button>
                                <button type="button" @click="deleted(departament.id)" v-tooltip.bottom-start="'Tooltip on bottom'"
                                    class="btn m-1 btn-outline-danger btn btn-sm ">
                                    <i class="fa fa-trash"></i> Eliminar
                                </button>
                               
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td align="center" colspan="5">Sin resultados.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <ModalFormComponent/>
        <ModalUserDepartament/>
    </div>
</template>