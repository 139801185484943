<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Empresas</h3>
				<div class="card-tools">				
					<router-link :to="`/companies/form`" v-can="'add_companies'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> Empresa
					</router-link>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th>Nombre</th>
								<th>R.F.C</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="companies">
							<tr v-for="(user, index) in companies.data" :key="index">
								<td>{{ user.id }}</td>
								<td>{{ user.name }}</td>
								<td>{{ user.rfc }}</td>								
								<td>
									<router-link :to="`/companies/form/${user.id}`" v-can="'edit_companies'" class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</router-link>
									<button type="button" @click="deleted(user.id)" v-can="'deleted_companies'" class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>				
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
// import pagination from 'laravel-vue-pagination';
import request from '@/services/request'
export default {
	name: "companies-table",
	// components: {
	// 	pagination,
	// },
	data() {
		return {
			companies: {},
			search: '',
		}
	},
	mounted() {
		this.list();
		this.setHeaderTitle('Empresas');
	},
	created() {
			Event.$on("event-load-companies", () => {
				this.list();
			});
	},
	methods: {
		deleted: function (id) {
			let tit = 'Eliminar empresa';
			let txt = 'Seguro que quiere eliminar la empresa';	

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/companies/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response;});
				this.list();
				this.successNotification(response.data.message);			
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport(){
			this.$modal.show('example');
		},
		async list(page = 1) {
			let service = request;
			let search = this.search;
			let _endpoint = '/companies';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.companies = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
