<template>
	<div class="col-12">
		<div class="card card-outline card-info">
			<div class="card-header">
				<h3 class="card-title">Lista de Proyectos</h3>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<label>Buscar :</label>
						<input type="text" class="form-control" v-model="search" @keyup="list()" />
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th>Perfil</th>
								<th>Área</th>
								<th>Num. Users</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="profiles">
							<tr v-for="(project, index) in profiles.data" :key="index">
								<td>{{ project.id }}</td>
								<td>
									<p v-if="project.profil">{{project.profil.name}}</p>
								</td>
								<td>
									<p v-if="project.area">{{project.area.name}}</p>
								</td>
								<td>
									{{project.users.length}}
								</td>
								<td>
									<router-link :to="`/permissions/form/${project.id}`"
										class="btn btn-outline-primary btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</router-link>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="5">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!-- <pagination align="center" :data="profiles" :limit="8" @pagination-change-page="list">
					<span slot="prev-nav">&lt; Anterior</span>
					<span slot="next-nav">Siguiente &gt;</span>
				</pagination> -->
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

// import pagination from 'laravel-vue-pagination'
import request from '@/services/request'

export default {
	name: "permissions-table",
	// components: {
	// 	pagination,
	// },
	data() {
		return {
			profiles: {},
			search: '',
		}
	},
	mounted() {
		this.setHeaderTitle('Permisos');
		this.list()
	},
	created() {
		Event.$on("event-load-profiles", () => {
			this.list();
		});
	},
	methods: {
		async list(page = 1) {
			let service = request;
			let search = this.search;
			let _endpoint = '/profiles';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					this.profiles = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			errorNotification: 'alert/error',
		})
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
