<template>
	<div class="row bg align-items-center justify-content-center">
		<div class="login-box">
			<div class="card card-outline" style="background-color: #efefef;">				
				<div class="card-body">
					<div class="col-12 text-center mb-4">
						<img src="@/assets/img/logo.png" class="mt-3 mb-4" alt="Logo" width="150px;"><br>
						<h4 class="login-box-msg mt-n2 text-secondary">Restablecer contraseña</h4>
						<p class="mt-n3">Ingresa el correo para enviar el link</p>
					</div>
					<form @submit.prevent="triggerResetPassword">
						<div class="col-12 mb-3">
							<div class="input-group ">								
								<input type="text" v-model="form.email" name="email" class="form-control"
									placeholder="Correo" :class="{ 'is-invalid': submitted && !form.email }" />
							</div>
							<div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
						</div>
						<div class="form-group text-center">
							<button class="btn btn-info btn-sm" style="width: 220px;" :disabled="submitted">Restablecer
								contraseña <i class="fa fa-paper-plane float-right mt-1" aria-hidden="true"></i></button>
						</div>
						<div class="col-md-12 text-center">
							<router-link to="/login" style="font-size:14px;" href="">Iniciar sesión</router-link>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	props: {
	},
	data() {
		return {
			form: {
				email: '',
			},
			errors: {},
			submitted: false
		}
	},
	methods: {
		async triggerResetPassword() {
			try {
				let reset = await this.resetPassword(this.form)
				if (reset) {
					this.successNotification(reset.message);
					this.$router.push('/login')
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					this.errors = await this.errorNotification(error)
				}
			}
		},
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	}
};
</script>
