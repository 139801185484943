<script>
import PageMenu from '@/pages/layouts/PageMenu.vue';
import titlePage from '@/pages/layouts/TitlePage.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		PageMenu,
		titlePage,
	},
	data() {
		return {
			imageBackground: `/img/oficina.jpg`,
		}
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	methods: {
		async postLogout() {
			try {
				let logout = await this.authLogout()
				if (logout) {
					this.$nextTick(() => {
						location.reload()
					})
				}

			} catch (error) {
				console.log(error);
			}
		},
		...mapActions({
			authLogout: 'auth/logout'
		})
	},
}
</script>
<template>
	<div class="wrapper">

		<!-- Navbar -->
		<nav class="main-header navbar navbar-expand navbar-white bg-dark">
			<!-- Left navbar links -->
			<ul class="navbar-nav">
				<li class="nav-item">
					<a class="nav-link" data-widget="pushmenu" href="#" role="button">
						<i class="fas fa-bars"></i></a>
				</li>
			</ul>

			<!-- Right navbar links -->
			<ul class="navbar-nav ml-auto">

				<!-- Notifications Dropdown Menu -->
				<li class="nav-item dropdown">
					<a class="nav-link" data-toggle="dropdown" href="#">
						<i class="fa fa-user-circle" aria-hidden="true"></i>
					</a>
					<div class="dropdown-menu dropdown-menu dropdown-menu-right">
						<router-link to="/profile" class="dropdown-item text-center">
							Editar cuenta
						</router-link>
						<div class="dropdown-divider"></div>
						<a href="#" @click="postLogout" class="dropdown-item text-center">
							<i class="fa fa-external-link" aria-hidden="true"></i> Cerrar sesión
						</a>
					</div>

				</li>
				<li class="nav-item">
					<a class="nav-link" data-widget="fullscreen" href="#" role="button">
						<i class="fas fa-expand-arrows-alt"></i>
					</a>
				</li>
			</ul>
		</nav>
		<!-- /.navbar -->

		<!-- Main Sidebar Container -->
		<aside class="main-sidebar elevation-4 sidebar-dark-navy">
			<!-- Brand Logo -->
			<a href="/admin/home" class="text-center">
				<div class="div_logo">
					<img src="@/assets/img/logo.png" alt="logo" class="img-circle elevation-3"
						style="opacity: .8; width: 80%; ">
				</div>
			</a>

			<!-- Sidebar -->
			<div class="sidebar">
				<!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
					<div class="image">
						<img :src="`${config.BaseUrl}/assets/img/logo.png`" class="img-circle elevation-2" alt="User Image">
					</div>
					<div class="info">
						<a href="#" class="d-block">{{ authUser.name }} {{ authUser.last_name }}</a>
					</div>
				</div> -->

				<PageMenu></PageMenu>

			</div>
			<!-- /.sidebar -->
		</aside>
		<div class="content-wrapper">
			<div class="col-12">

				<!-- Main content -->
				<section class="content">
					<div class="container-fluid">
						<titlePage />
						<div class="container-fluid">
							<router-view></router-view>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>
